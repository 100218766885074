/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useRef, useMemo, useState, useLayoutEffect } from "react";
import { useFocusRing, useTextField } from "react-aria";

import examples from "libphonenumber-js/mobile/examples";
import {
  parseIncompletePhoneNumber,
  formatIncompletePhoneNumber,
  getExampleNumber,
  isSupportedCountry,
  AsYouType,
  type CountryCode,
  parseDigits,
} from "libphonenumber-js";

import { useLocale } from "@superweb/intl";

import { useUiColors } from "../theme";
import { ClearButton } from "../fields/buttons";
import { Field, useTextInputStyle } from "../fields/field";
import { FieldLabelV2 } from "../fields/label";
import { FieldDescription } from "../fields/description";
import { FieldErrorMessage } from "../fields/error-message";
import { CountryIcon } from "./country-icon";
import { useUiOptions } from "../ui-options-context";
import { useMessage } from "#intl";

type InvalidRules = {
  required: boolean;
};

export type PhoneFieldState = {
  /**
   * Current phone input's value.
   * If consists of phone number characters, value will be displayed in the input. Otherwise, it will not.
   */
  value: string;

  /**
   * Is set to `true` on change if the field contains partial or invalid phone number.
   */
  invalid?: boolean;

  /**
   * When set `false` the error message is not visible even if is set.
   * Is set on change depending on the interaction.
   * Can be set externally to force hide/show the error message.
   * The field has invalid state when both the `errorMessage` is not empty and `errorVisible` is `true`.
   */
  errorVisible?: boolean;

  /**
   * The error message associated with the field.
   * Visible only when `errorVisible` is `true`.
   * The field has invalid state when both the `errorMessage` is not empty and `errorVisible` is `true`.
   */
  errorMessage?: string;
};

const formatter = new AsYouType();

/**
 * Phone Field let users enter and edit phone number.
 */
export const PhoneField = ({
  label,
  description,
  required = false,
  disabled = false,
  country,
  state,
  onChange,
}: {
  /**
   * Text for field's label, that describes field's meaning.
   * No need to specify input examples in label's text.
   * The label is used for the accessibility of the element
   *
   * Links:
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/label
   * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-label
   * https://www.w3.org/WAI/tutorials/forms/labels/
   */
  label: string;

  /**
   * Text for field's description, that describes in detail the purpose of this field.
   *
   * Links:
   * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-description
   */
  description?: string;

  /**
   * If `true`, the input element is required.
   * @defaultValue false
   */
  required?: boolean;

  /**
   * If `true`, the component is disabled.
   * @defaultValue false
   */
  disabled?: boolean;

  /**
   * Current field's state.
   * The state stores the interactive fields, that change when interacting with the component.
   */
  state: PhoneFieldState;

  /**
   * Callback fired when the state is changed.
   * @param state - Recommended state for the component after the change.
   */
  onChange: (
    state: PhoneFieldState,
    info: {
      invalid: {
        required: boolean;
      };
    },
  ) => void;

  /**
   * Default phone field's country code.
   * Phone number example with this country code is used as a default placeholder,
   */
  country?: string;
}) => {
  const uiOptions = useUiOptions();
  const enableInputAtClear = uiOptions.experimental?.enableInputAtClear;
  const getErrorMessage = useErrorMessage();

  const uiColors = useUiColors();
  const inputCss = useTextInputStyle({
    disabled,
    icon: true,
  });
  const {
    textInfo: { direction },
  } = useLocale();

  const ref = useRef<HTMLInputElement>(null);
  const selection = useRef({ update: false, ...getSelection(ref.current) });

  const { isFocused, focusProps } = useFocusRing({
    isTextInput: true,
    within: true,
  });

  const defaultCountry = useMemo(
    () =>
      country && isSupportedCountry(country)
        ? (country as CountryCode)
        : undefined,
    [country],
  );

  const handleChange = ({
    currentState,
  }: {
    currentState: PhoneFieldState;
  }) => {
    const currentValue = currentState.value;

    const invalidRules = validatePhoneFieldValue({
      value: currentValue,
      required,
    });

    const invalid = Object.values(invalidRules).some(Boolean);

    const errorMessage = invalid ? getErrorMessage(invalidRules) : undefined;

    onChange(
      {
        ...currentState,
        errorMessage,
        errorVisible: currentState.errorVisible,
      },
      {
        invalid: {
          required: invalidRules.required,
        },
      },
    );
  };

  const [inputValue, currentCountry] = useMemo(() => {
    formatter.reset();
    formatter.input(state.value);

    const phone = formatter.getNumber();

    return phone?.isValid()
      ? [phone.formatInternational(), phone.country]
      : [formatIncompletePhoneNumber(state.value), formatter.getCountry()];
  }, [state.value]);

  const updatePhoneFormat = (value: string, isErase = false) => {
    let phone = value;

    if (value && isDigits(value) && !value.startsWith("+")) {
      phone = `+${phone}`;
    }

    formatter.reset();
    formatter.input(phone);

    const { selectionStart } = getSelection(ref.current);

    if (!isErase && selectionStart !== value.length) {
      if (selectionStart && value[selectionStart] === " ") {
        selection.current = {
          selectionStart: selectionStart + 1,
          selectionEnd: selectionStart + 1,
          update: true,
        };
      } else {
        selection.current = {
          selectionStart,
          selectionEnd: selectionStart,
          update: true,
        };
      }
    }

    onChange(
      {
        ...state,
        invalid: phone !== "" && !formatter.isValid(),
        value:
          formatter.getNumber()?.number || parseIncompletePhoneNumber(phone),
        errorVisible: false,
        errorMessage: undefined,
      },
      {
        invalid: {
          required: false,
        },
      },
    );
  };

  const onInputChange = (value: string) => {
    updatePhoneFormat(value, false);
  };

  const erase = (start: number, end: number) => {
    updatePhoneFormat(
      `${inputValue.slice(0, start)}${inputValue.slice(end)}`,
      true,
    );
    if (inputValue[start + 1] !== undefined) {
      selection.current = {
        selectionStart: start,
        selectionEnd: start,
        update: true,
      };
    }
  };

  useLayoutEffect(() => {
    if (
      ref.current &&
      selection.current.update &&
      selection.current.selectionStart !== undefined
    ) {
      ref.current.setSelectionRange(
        selection.current.selectionStart,
        selection.current.selectionStart,
      );
      selection.current.update = false;
    }
  }, [state.value]);

  const { labelProps, inputProps, descriptionProps, errorMessageProps } =
    useTextField(
      {
        type: "tel",
        label,
        description,
        isRequired: required,
        isDisabled: disabled,
        value: inputValue,
        validationState:
          state.errorVisible && state.errorMessage ? "invalid" : "valid",

        onChange: onInputChange,

        onKeyDown: (e) => {
          switch (e.key) {
            case "Backspace":
            case "Delete":
              //processing when pressing cmd + delete, opt + delete on Mac and Ctrl + Backspace on Windows
              if (e.altKey || e.metaKey || e.ctrlKey) {
                return;
              }

              const { selectionStart, selectionEnd } = getSelection(
                ref.current,
              );

              if (selectionStart === undefined || selectionEnd === undefined)
                return;

              e.preventDefault();

              if (selectionStart !== selectionEnd) {
                erase(selectionStart, selectionEnd);
                return;
              }

              const isBackspace = e.key === "Backspace";

              const { start, end } = isBackspace
                ? { start: selectionStart - 1, end: selectionStart }
                : { start: selectionStart, end: selectionStart + 1 };

              if (selectionStart > 0) {
                if (inputValue.length === 1 || isDigits(inputValue[start])) {
                  erase(start, end);
                } else {
                  ref.current?.setSelectionRange.apply(
                    ref.current,
                    isBackspace ? [start, start] : [end, end],
                  );
                }
              }

              break;
          }
        },

        onBlur: () => {
          handleChange({ currentState: { ...state, errorVisible: true } });
        },
      },
      ref,
    );

  const isShrunk = isFocused || Boolean(state.value);

  const onClearButtonPress = () => {
    ref.current?.focus();
    onChange(
      {
        ...state,
        value: "",
        errorVisible: false,
      },
      {
        invalid: {
          required: false,
        },
      },
    );
  };

  const formattedPlaceholder = useMemo(() => {
    if (defaultCountry) {
      const exampleNumber = getExampleNumber(defaultCountry, examples)?.number;

      return exampleNumber
        ? formatIncompletePhoneNumber(exampleNumber, defaultCountry)
        : undefined;
    }

    return undefined;
  }, [defaultCountry]);

  return (
    <Field
      enableInputAtClear={enableInputAtClear}
      icon={<CountryIcon region={currentCountry} />}
      dir="ltr"
      fieldProps={focusProps}
      shrunk={isShrunk}
      focused={isFocused}
      stretchInputElement={true}
      disabled={disabled}
      label={
        <FieldLabelV2
          shrunk={isShrunk}
          labelProps={labelProps}
          dir={direction === "rtl" ? "ltr" : undefined}
        >
          {label}
        </FieldLabelV2>
      }
      input={
        <input
          {...inputProps}
          placeholder={formattedPlaceholder}
          __experimental_placeholderCss={{
            color: uiColors.textMinor,
            opacity: isFocused ? "1" : "0",
            transitionDuration: isFocused ? "300ms" : undefined,
          }}
          ref={ref}
          css={inputCss}
        />
      }
      clearButton={
        <ClearButton
          visible={Boolean(state.value) && isFocused}
          onPress={onClearButtonPress}
        />
      }
      descriptionAndError={
        state.errorVisible && state.errorMessage ? (
          <FieldErrorMessage errorMessageProps={errorMessageProps}>
            {state.errorMessage}
          </FieldErrorMessage>
        ) : (
          description && (
            <FieldDescription descriptionProps={descriptionProps}>
              {description}
            </FieldDescription>
          )
        )
      }
      onClick={() => {
        ref.current?.focus();
      }}
    />
  );
};

export const createPhoneFieldState = (
  defaultValue?: PhoneFieldState,
): PhoneFieldState => {
  return (
    defaultValue ?? {
      value: "",
      invalid: false,
      errorVisible: false,
      errorMessage: undefined,
    }
  );
};

export const usePhoneFieldState = (defaultValue?: PhoneFieldState) => {
  return useState<PhoneFieldState>(createPhoneFieldState(defaultValue));
};

const getSelection = (el: HTMLInputElement | null) => {
  const { selectionStart: start, selectionEnd: end } = el ?? {};
  return {
    selectionStart: start ?? undefined,
    selectionEnd: end ?? undefined,
  };
};

const isDigits = (char?: string) => {
  if (char === undefined) return false;
  return Boolean(parseDigits(char));
};

const useErrorMessage = () => {
  const message = useMessage();

  return (invalidRules: InvalidRules) => {
    if (invalidRules.required) {
      return message({
        id: "41396b90-b1c6-403f-b15f-50ff35dc9c10",
        context:
          "Phone field. Error message in platform libraries in the Phone field component.",
        default: "Phone is required",
      });
    }

    return undefined;
  };
};

const validatePhoneFieldValue = ({
  value,
  required,
}: {
  value: string;
  required?: boolean;
}) => {
  const invalid = {
    required: Boolean(required && !value),
  };

  return invalid;
};
