import type { PointerEvent } from "react";
import {
  resolvePath,
  useLocation,
  type To,
  useNavigate,
} from "react-router-dom";

export const useMakeLink = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (path: To) => {
    const locationPathname = location.pathname;
    const toPathname = resolvePath(path).pathname;

    const isActive =
      locationPathname === toPathname ||
      locationPathname.startsWith(`${toPathname}/`);

    return {
      href: toPathname,
      onClick: (e: PointerEvent<HTMLAnchorElement>) => {
        if (
          e.ctrlKey ||
          e.metaKey ||
          e.altKey ||
          e.shiftKey ||
          e.button !== 0
        ) {
          return;
        }
        e.preventDefault();
        navigate(toPathname);
      },
      isActive,
    };
  };
};
