/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { StrictMode, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@superweb/api";
import { HelmetProvider } from "react-helmet-async";

import { App } from "./app";
import { MetrikaProvider } from "#internal/metrika";
import { sendTimeMark, sendHeroElement } from "./rum";

import "intl-pluralrules";

const queryClient = new QueryClient();

const AppWithCallbackAfterRender = () => {
  // https://react.dev/blog/2022/03/08/react-18-upgrade-guide#updates-to-client-rendering-apis
  useEffect(() => {
    sendTimeMark("js_framework_inited");
    sendHeroElement();
  });

  return (
    <StrictMode>
      <BrowserRouter>
        <MetrikaProvider counterId={89693263}>
          <HelmetProvider>
            <QueryClientProvider client={queryClient}>
              <App />
            </QueryClientProvider>
          </HelmetProvider>
        </MetrikaProvider>
      </BrowserRouter>
    </StrictMode>
  );
};

createRoot(document.getElementById("root")!).render(
  <AppWithCallbackAfterRender />,
);
