import { createPortal } from "react-dom";
import { type ReactNode, createContext, useContext } from "react";

export const GuidanceOverlayContext = createContext<
  | {
      container: HTMLDivElement | null;
    }
  | undefined
>(undefined);

export const GuidanceOverlayContextProvider = GuidanceOverlayContext.Provider;

export const ExperimentalGuidanceOverlay = ({
  children,
}: {
  children: ReactNode;
}) => {
  const guidanceOverlayContext = useContext(GuidanceOverlayContext);
  if (!guidanceOverlayContext)
    throw new Error(
      "Guidance overlay must have an access to GuidanceOverlayContext",
    );
  const { container } = guidanceOverlayContext;

  return container ? createPortal(children, container) : children;
};
