/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { cssFns } from "@superweb/css";
import { useIsMobile, useUiColors } from "@superweb/ui";
import type { ReactNode } from "react";

export const BaseLayout = ({
  headerComponent,
  children,
}: {
  headerComponent?: ReactNode;
  children: ReactNode;
}) => {
  const uiColors = useUiColors();
  const isMobile = useIsMobile();

  return (
    <div
      css={{
        display: "grid",
        height: "100%",
      }}
    >
      <div
        css={{
          boxSizing: "border-box",
          backgroundColor: uiColors.background,
          ...(!isMobile && {
            ...cssFns.border({ radius: "16px" }),
            ...cssFns.margin("20px", "auto"),
            maxWidth: "560px",
            width: "100%",
          }),
          display: "grid",
          gridTemplateRows: headerComponent ? "min-content 1fr" : "1fr",
        }}
      >
        {headerComponent}
        {children}
      </div>
    </div>
  );
};
