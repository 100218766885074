/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useTypo, useUiColors } from "@superweb/ui";

import { Message } from "#internal/intl";
import { useMe } from "#internal/api/hiring-partners-app/users";

export const UserStatus = ({
  status,
}: {
  status: "created" | "deactivated";
}) => {
  const typo = useTypo();
  const uiColors = useUiColors();
  const { data: { data, headers } = { headers: { requestId: "" } } } = useMe();

  return (
    <div
      css={{
        display: "grid",
        alignContent: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <span
        css={typo({
          level: "title4",
          weight: "regular",
          density: "normal",
        })}
      >
        {status === "created" && (
          <Message
            id="1e78968b-9dba-4bf7-a152-55cf45f44b9c"
            context="Main screen. Status message"
            default="Please wait for activation"
          />
        )}
        {status === "deactivated" && (
          <Message
            id="bed3670c-c5a7-4861-968e-45d7bfb2c3c2"
            context="Main screen. Status message"
            default="User deactivated"
          />
        )}
      </span>
      <div
        css={{
          color: uiColors.textMinor,
          ...typo({
            level: "body2",
            weight: "regular",
            density: "tight",
          }),
        }}
      >
        {[data?.first_name, data?.last_name, data?.username]
          .filter(Boolean)
          .join(" ")}
      </div>
      <div
        css={{
          color: uiColors.textMinor,
          ...typo({
            level: "caption1",
            weight: "regular",
            density: "loose",
          }),
        }}
      >
        {headers.requestId}
      </div>
    </div>
  );
};
