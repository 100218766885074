/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import {
  createContext,
  useContext,
  useEffect,
  useId,
  type MutableRefObject,
} from "react";
import { createPortal } from "react-dom";

import { cssFns, type Style } from "@superweb/css";
import { useLocale } from "@superweb/intl";

import { icons } from "../icons";
import { useIsMobile } from "../mobile-context";
import { useUiColors, useUiShadows } from "../theme";
import { useTypo } from "../typo";
import { useUiOptions } from "../ui-options-context";

const DEFAULT_TIMEOUT = 3000;

export type SnackbarProps = {
  /**
   * The text displayed in the body of snackbar.
   */
  text: string;

  /**
   * The number of milliseconds to wait before automatically calling the onClose function.
   * @defaultValue `3000`
   */
  timeout?: number;

  /**
   * Visual variant of snackbar.
   */
  view?: "critical" | "info" | "success";

  /**
   * The text is displayed on the action button. The button is displayed if the text is set.
   */
  action?: string;

  /**
   * Callback fired when mouse, touch or keyboard event occur on close button.
   */
  onClose: () => void;

  /**
   * Callback fired when mouse, touch or keyboard event occur on action button.
   */
  onAction?: () => void;
};

const SnackbarContext =
  createContext<MutableRefObject<HTMLDivElement | null> | null>(null);

export const SnackbarContextProvider = SnackbarContext.Provider;

export const useSnackbarContext = () => useContext(SnackbarContext);

const SnackbarNew = ({
  timeout = DEFAULT_TIMEOUT,
  view = "info",
  text,
  action,
  onClose,
  onAction,
}: SnackbarProps) => {
  const id = useId();
  const isMobile = useIsMobile();
  const locale = useLocale();
  const ref = useSnackbarContext();
  const typo = useTypo();
  const uiColors = useUiColors();
  const uiShadows = useUiShadows();

  useEffect(() => {
    const t = setTimeout(() => onClose(), timeout);
    return () => clearTimeout(t);
  }, [timeout, onClose]);

  return ref?.current
    ? createPortal(
        <div
          role="alert"
          aria-modal="false"
          aria-labelledby={id}
          css={{
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            columnGap: "16px",
            minWidth: "328px",
            maxWidth: "764px",
            ...cssFns.padding("12px", "16px"),
            marginBlockStart: "10px",
            color: view === "info" ? uiColors.textInvert : uiColors.everFront,
            backgroundColor: {
              critical: uiColors.statuses.danger,
              info: uiColors.backgroundInvert,
              success: uiColors.statuses.success,
            }[view],
            ...cssFns.border({
              radius: "16px",
            }),
            boxShadow: uiShadows.bottomNormal,
            pointerEvents: "auto",
          }}
        >
          <span
            css={{
              flexShrink: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {view === "success" ? (
              <icons.CheckRoundFill />
            ) : (
              <icons.WarningFill />
            )}
          </span>

          <span
            id={id}
            lang={locale.language}
            css={{
              flexGrow: "1",
              ...typo({
                level: isMobile ? "caption1" : "body2",
                density: "tight",
                weight: "regular",
              }),
              hyphens: "auto",
              wordBreak: "break-word",
              ...cssFns.overflow("hidden"),
            }}
            {...(!isMobile && {
              style: {
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                textOverflow: "ellipsis",
              },
            })}
          >
            {text}
          </span>

          {action && (
            <button
              css={{
                flexShrink: "0",
                display: "flex",
                justifyContent: "center",
                ...cssFns.padding("8px", "14px"),
                ...cssFns.margin("0"),
                color: "inherit",
                backgroundColor: "transparent",
                ...cssFns.border({
                  width: "0.5px",
                  style: "solid",
                  color:
                    view === "info" ? uiColors.textInvert : uiColors.everFront,
                  radius: "10px",
                }),
                cursor: "pointer",
              }}
              onClick={onAction}
            >
              {action}
            </button>
          )}

          <button
            css={{
              flexShrink: "0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              ...cssFns.padding("0"),
              ...cssFns.margin("0"),
              color: "inherit",
              backgroundColor: "transparent",
              ...cssFns.border({
                width: "0",
              }),
              cursor: "pointer",
            }}
            onClick={onClose}
          >
            <icons.Cross />
          </button>
        </div>,
        ref.current,
      )
    : null;
};

const SnackbarOld = ({
  timeout = DEFAULT_TIMEOUT,
  view,
  text,
  action,
  onClose,
  onAction,
}: SnackbarProps) => {
  const typo = useTypo();
  const id = useId();

  const uiColors = useUiColors();
  const uiShadows = useUiShadows();
  const ref = useSnackbarContext();

  const buttonStyle: Style = {
    ...cssFns.padding("0"),
    ...cssFns.margin("0"),
    ...cssFns.border({ width: "0" }),
    cursor: "pointer",
    justifyContent: "center",
    flexShrink: "0",
    display: "flex",
    marginInlineStart: "8px",
    backgroundColor: "transparent",
    color: cssFns.setOpacity(
      view === "critical" ? uiColors.text : uiColors.textInvert,
      0.5,
    ),
  };

  useEffect(() => {
    const t = setTimeout(() => onClose(), timeout);
    return () => clearTimeout(t);
  }, [timeout, onClose]);

  return ref?.current
    ? createPortal(
        <div
          role="alertdialog"
          aria-modal="false"
          aria-labelledby={id}
          css={{
            marginBlockEnd: "10px",
            pointerEvents: "auto",
            color:
              view === "critical" ? uiColors.everFront : uiColors.textInvert,
            display: "flex",
            justifyContent: "space-between",
            boxSizing: "border-box",
            alignContent: "center",
            alignItems: "center",
            maxWidth: "100%",
            boxShadow: uiShadows.bottomNormal,
            backgroundColor:
              view === "critical"
                ? uiColors.alerts.error
                : uiColors.backgroundInvert,
            ...cssFns.border({ radius: "16px" }),
            ...cssFns.padding("10px", "12px"),
            ...cssFns.overflow("hidden"),
          }}
        >
          <span
            id={id}
            css={{
              ...typo({
                level: "body2",
                density: "normal",
                weight: "regular",
              }),
            }}
          >
            {text}
          </span>

          {action && (
            <button css={buttonStyle} onClick={onAction}>
              {action}
            </button>
          )}

          <button css={buttonStyle} onClick={onClose}>
            <icons.Cross />
          </button>
        </div>,
        ref.current,
      )
    : null;
};

export const Snackbar = (props: SnackbarProps) => {
  const uiOptions = useUiOptions();
  const enableNewSnackbarDesign =
    uiOptions.experimental?.enableNewSnackbarDesign;

  return enableNewSnackbarDesign ? (
    <SnackbarNew {...props} />
  ) : (
    <SnackbarOld {...props} />
  );
};
