/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useFocusRing, mergeProps } from "react-aria";
import { cssFns, type Style } from "@superweb/css";
import {
  forwardRef,
  type ChangeEventHandler,
  type ComponentType,
  type InputHTMLAttributes,
  type KeyboardEventHandler,
  type Ref,
} from "react";
import { useUiColors } from "../theme";
import { icons } from "../icons";
import { useTypo } from "../typo";

type View = "floating";
type Size = "s" | "m";
type Shape = "circle";

const useBoxStyle = (
  size: Size,
  textAlign: "start" | "end",
  hasError?: boolean,
  hasIcon?: boolean,
): Style => {
  const base = {
    ...cssFns.padding("16px"),
    outlineStyle: "none",
    boxSizing: "border-box",
    width: "100%",
    ...(hasIcon &&
      (textAlign === "end"
        ? { paddingInlineEnd: "42px" }
        : { paddingInlineStart: "42px" })),
    ...(hasError &&
      (textAlign === "start"
        ? { paddingInlineEnd: "42px" }
        : { paddingInlineStart: "42px" })),
  };

  switch (size) {
    case "s":
      return {
        ...base,
        ...cssFns.border({ width: "0px", radius: "13px" }),
        maxHeight: "40px",
      };
    case "m":
      return {
        ...base,
        ...cssFns.border({ width: "0px", radius: "16px" }),
        maxHeight: "48px",
      };
  }
};

const useColorStyle = (disabled: boolean): Style => {
  const uiColors = useUiColors();
  return {
    color: uiColors.text,
    backgroundColor: uiColors.controlMinor,
    opacity: disabled ? ".5" : "1",
  };
};

export const TextInput = forwardRef<
  HTMLInputElement,
  {
    value?: string | ReadonlyArray<string> | number;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
    placeholder?: string;
    view?: View;
    shape?: Shape;
    size?: Size;
    inputProps?: InputHTMLAttributes<HTMLInputElement>;
    ref?: Ref<HTMLInputElement>;
    errorMessage?: string;
    disabled?: boolean;
    icon?: ComponentType<{ className?: string }>;
    dir?: "rtl" | "ltr";
    textAlign?: "start" | "end";
  }
>(
  (
    {
      view = "floating",
      shape = "circle",
      size = "m",
      inputProps,
      onChange,
      onKeyDown,
      errorMessage,
      disabled = false,
      icon: Icon,
      dir,
      textAlign = "start",
      ...props
    },
    ref,
  ) => {
    const uiColors = useUiColors();
    const typo = useTypo();
    const hasError = Boolean(errorMessage);

    const boxStyle = useBoxStyle(size, textAlign, hasError, Boolean(Icon));
    const colorStyle = useColorStyle(disabled);
    const { focusProps, isFocusVisible: isFocused } = useFocusRing();
    const style: Style = {
      display: "inline-flex",
      ...cssFns.gridArea("1"),
      ...typo({
        level: "body2",
        density: "normal",
        weight: "regular",
      }),
      ...boxStyle,
      ...colorStyle,
      ...cssFns.boxShadow(
        isFocused && {
          inset: true,
          spreadRadius: "2px",
          color: uiColors.focus,
        },
      ),
      textAlign,
    };

    return (
      <div
        css={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          dir={dir}
          css={{
            display: "grid",
          }}
        >
          <input
            ref={ref}
            onChange={onChange}
            onKeyDown={onKeyDown}
            css={style}
            disabled={disabled}
            {...mergeProps(focusProps, inputProps ?? {}, props)}
          />
          {Icon && (
            <div
              css={{
                display: "grid",
                ...cssFns.gridArea("1"),
                ...cssFns.placeSelf("center", textAlign),
                ...(textAlign === "end"
                  ? {
                      marginInlineEnd: "12px",
                    }
                  : {
                      marginInlineStart: "12px",
                    }),
              }}
            >
              <Icon />
            </div>
          )}
          {hasError && (
            <div
              css={{
                display: "grid",
                ...cssFns.gridArea("1"),
                ...cssFns.placeSelf(
                  "center",
                  textAlign === "end" ? "start" : "end",
                ),
                ...(textAlign === "end"
                  ? {
                      marginInlineStart: "12px",
                    }
                  : {
                      marginInlineEnd: "12px",
                    }),
                color: uiColors.alerts.error,
              }}
            >
              <icons.Alert />
            </div>
          )}
        </div>
        {hasError && (
          <span
            css={{
              ...typo({
                level: "caption1",
                weight: "regular",
                density: "normal",
              }),
              color: uiColors.alerts.error,
            }}
          >
            {errorMessage}
          </span>
        )}
      </div>
    );
  },
);
