/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { Navigate, useLocation, useSearchParams } from "react-router-dom";

import { useLocale } from "@superweb/intl";

import { getPassportUrl } from "#internal/utils/passport";
import { triggerLink } from "#internal/utils/triggerLink";
import {
  referralAltCheckLink,
  referralAltCheckLinkParams,
  referralAltLinkParams,
} from "#internal/routes";
import { useCheckReferralLink } from "#internal/api/hiring-partners-app/users";

import { HttpErrors } from "../team-referral";
import { RegistrationFormPage } from "./form-page";
import { FinalScreen } from "./final-screen";
import { RedirectToPassport } from "./redirect-to-passport";
import { BaseLayout } from "./base-layout";
import { WelcomeContent, WelcomeHeaderComponent } from "./welcome";
import { ReferralFormProvider } from "./form-provider";

export const RedirectFromTeamReferralAltLink = ({
  onLanguageChange,
}: {
  onLanguageChange: (language: string | undefined) => void;
}) => {
  return (
    <HttpErrors
      onLanguageChange={onLanguageChange}
      RedirectComponent={RedirectToPassport}
    >
      <RedirectFromTeamReferralAltLinkPage />
    </HttpErrors>
  );
};

const RedirectFromTeamReferralAltLinkPage = () => {
  const location = useLocation();
  const { referralId = "" } = referralAltLinkParams(location);
  const [searchParams] = useSearchParams();

  const locale = useLocale();
  const language = searchParams.get("locale") ?? locale.language;

  const { data: { data } = {} } = useCheckReferralLink(referralId, {
    retry: false,
    enabled: Boolean(referralId),
    suspense: true,
  });

  const redirectToPassport = () => {
    if (referralId) {
      const passportAuthUrl = getPassportUrl({
        lang: language,
        retpath: `${window.location.origin}${referralAltCheckLink(referralId)}?locale=${language}`,
      });

      triggerLink(passportAuthUrl, { target: "_self" });
    }
  };

  if (data?.user_exists && data.user_details?.status !== "created") {
    return <Navigate to="/" />;
  }

  return (
    <BaseLayout headerComponent={<WelcomeHeaderComponent />}>
      <WelcomeContent redirectToPassport={redirectToPassport} />
    </BaseLayout>
  );
};

export const ReferralAltLinkCheck = ({
  onLanguageChange,
}: {
  onLanguageChange: (language: string | undefined) => void;
}) => {
  return (
    <HttpErrors
      RedirectComponent={RedirectToPassport}
      onLanguageChange={onLanguageChange}
    >
      <ReferralAltLinkCheckPage />
    </HttpErrors>
  );
};

const ReferralAltLinkCheckPage = () => {
  const location = useLocation();
  const { referralId = "" } = referralAltCheckLinkParams(location);

  const { data: { data } = {}, refetch } = useCheckReferralLink(referralId, {
    retry: false,
    suspense: true,
  });

  if (data?.user_exists) return <FinalScreen />;

  return (
    <ReferralFormProvider>
      <RegistrationFormPage onRegistration={refetch} referralId={referralId} />
    </ReferralFormProvider>
  );
};
