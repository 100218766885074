/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useEffect, useState, type ReactNode } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useLocale } from "@superweb/intl";
import { Button, Snackbar, icons, useTypo } from "@superweb/ui";
import { cssFns } from "@superweb/css";

import { Message, useMessage } from "#internal/intl";
import { referralAltLink } from "#internal/routes";
import { useMetrika } from "#internal/metrika";
import { useV1UsersPlatformProfileCanCreatePost } from "#internal/api/hiring-partners-app/users";

import { Form } from "./form";
import { BaseLayout } from "./base-layout";
import { ProfileFoundPageContent } from "./profile-found";
import { ErrorPageContent } from "./error-screen";

export type Screen = "info" | "photo" | "error";

const checkProfileGoal = "cbcda006-a59d-4cd4-b0fa-daacc0954e7c";

const Header = ({
  title,
  handlePrev,
}: {
  title?: ReactNode;
  handlePrev: () => void;
}) => {
  const typo = useTypo();
  const message = useMessage();

  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        css={{
          ...cssFns.padding("4px"),
        }}
      >
        <Button
          view="ghost"
          icon={icons.ArrowLeft}
          ariaLabel={message({
            id: "d7696e49-ab84-4d78-a021-b104fb840e47",
            context: "Self-registration form. Navigation button",
            default: "Previous screen",
          })}
          onPress={handlePrev}
        />
      </div>
      {title ? (
        <h1
          css={{
            ...typo({
              level: "title3",
              weight: "regular",
              density: "tight",
            }),
            ...cssFns.margin("0"),
          }}
        >
          {title}
        </h1>
      ) : undefined}
    </div>
  );
};

const showFormGoal = "143c0aaf-e9b2-4bf3-ba9c-03f54bbd8f93";
const clickNextStepGoal = "33287052-4e8b-4a48-98af-fa62aee569d7";
const clickPrevStepGoal = "861ba22c-aa6b-4fab-9ec5-fd2215c4c041";

export const RegistrationFormPage = ({
  onRegistration,
  referralId,
}: {
  onRegistration: () => void;
  referralId: string;
}) => {
  const message = useMessage();
  const [screen, setScreen] = useState<Screen>("info");
  const [errorMessage, setErrorMessage] = useState("");
  const { reachGoal } = useMetrika();
  const [searchParams] = useSearchParams();
  const locale = useLocale();
  const language = searchParams.get("locale") ?? locale.language;
  const navigate = useNavigate();

  const setNextStep = () => {
    reachGoal(clickNextStepGoal);
    setScreen("photo");
  };

  const headerTitle = screen !== "error" && (
    <Message
      id="10339a0a-f372-468b-bd4c-5b14da81f9b4"
      context="Self-registration form. Title"
      default="Registration"
    />
  );

  const {
    mutate: checkProfile,
    isLoading: isCheckProfileLoading,
    data,
    isSuccess,
  } = useV1UsersPlatformProfileCanCreatePost({
    onSuccess: (result) => {
      if (result.ok) {
        setNextStep();
        return;
      }
      setScreen("error");
    },
    onError: () => {
      setErrorMessage(
        message({
          id: "b8419a9e-65be-4521-9559-9d1c4ddb8ee6",
          context: "Self-registration form. Snackbar. Error text",
          default: "Platform temporarily unavailable",
        }),
      );
    },
  });

  const handleNextStep = (phone: string) => {
    reachGoal(checkProfileGoal);
    checkProfile({
      headers: { "content-type": "application/json" },
      body: {
        phone,
        ref_id: referralId!,
      },
    });
  };

  const setPrevStep = () => {
    reachGoal(clickPrevStepGoal);
    if (screen === "info") {
      navigate(referralAltLink({ id: referralId, locale: language }));
      return;
    }
    setScreen("info");
  };

  useEffect(() => {
    reachGoal(showFormGoal);
  }, [reachGoal]);

  if (isSuccess && !data.ok && screen === "error") {
    if (data.error.code === "active_driver_profile_found") {
      return <ProfileFoundPageContent />;
    }

    return (
      <BaseLayout
        headerComponent={<Header handlePrev={() => setScreen("info")} />}
      >
        <ErrorPageContent
          traceId={data.trace_id}
          title={message({
            id: "14d2b95d-92d9-4508-b896-35268ce99ff2",
            context: "Self-registration form. Error screen. Title",
            default: "Something went wrong",
          })}
          description={message({
            id: "7b776623-ebc5-4d6a-8217-d75a7aa91ac8",
            context: "Self-registration form. Error screen. Description",
            default:
              "We're currently experiencing some technical issues, please try again later",
          })}
        />
      </BaseLayout>
    );
  }

  return (
    <BaseLayout
      headerComponent={<Header title={headerTitle} handlePrev={setPrevStep} />}
    >
      <Form
        onRegistration={onRegistration}
        screen={screen}
        referralId={referralId}
        onNextStep={handleNextStep}
        isCheckProfileLoading={isCheckProfileLoading}
        setErrorMessage={setErrorMessage}
      />
      {errorMessage && (
        <Snackbar
          view="critical"
          text={errorMessage}
          onClose={() => {
            setErrorMessage("");
          }}
        />
      )}
    </BaseLayout>
  );
};
