/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useTypo } from "@superweb/ui";

import { Message, useMessage } from "#internal/intl";

import logo from "./logo.png";

export const Logo = ({ showText }: { showText?: boolean }) => {
  const message = useMessage();
  const typo = useTypo();

  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        columnGap: "12px",
      }}
    >
      <img
        src={logo}
        alt={message({
          id: "1efce2e6-d1ae-46c1-a70a-8bc8d5033935",
          context: "Логотип. Альтернативное описание",
          default: "Taxi partner account logo",
        })}
        css={{
          display: "block",
          height: "45px",
        }}
      />

      {showText && (
        <div
          css={{
            display: "grid",
            rowGap: "1px",
          }}
        >
          <span
            css={typo({ level: "body2", density: "tight", weight: "regular" })}
          >
            <Message
              id="fa4e8f89-fe6e-4626-a8d0-bc5f3c6d0caa"
              context="Logo. Text"
              default="Partner account"
            />
          </span>
        </div>
      )}
    </div>
  );
};
