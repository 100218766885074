/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import type { SVGProps } from "react";

export const IconServiceAppFrameYango = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" {...props}>
      <g clipPath="url(#b)">
        <mask
          id="a"
          x="0"
          y="0"
          width="40"
          height="40"
          maskUnits="userSpaceOnUse"
        >
          <path
            d="M0 19.2C0 12.4794 0 9.11905 1.30792 6.55211C2.4584 4.29417 4.29417 2.4584 6.55211 1.30792C9.11905 0 12.4794 0 19.2 0H20.8C27.5206 0 30.8809 0 33.4479 1.30792C35.7058 2.4584 37.5416 4.29417 38.6921 6.55211C40 9.11905 40 12.4794 40 19.2V20.8C40 27.5206 40 30.8809 38.6921 33.4479C37.5416 35.7058 35.7058 37.5416 33.4479 38.6921C30.8809 40 27.5206 40 20.8 40H19.2C12.4794 40 9.11905 40 6.55211 38.6921C4.29417 37.5416 2.4584 35.7058 1.30792 33.4479C0 30.8809 0 27.5206 0 20.8V19.2Z"
            fill="#fff"
          />
        </mask>
        <g mask="url(#a)">
          <rect width="40" height="40" fill="#f00" />
          <path
            d="m8.4616 15.181h-2.1206l-0.02719 6.2124-1.1419 3.4392h2.1478l1.0875-3.2761 4.1597-6.3755h-2.4061l-1.373 2.5285c-0.25828 0.4622-0.46219 0.8836-0.53015 1.0467 0.02718-0.1767 0.06796-0.7205 0.09515-1.1691l0.10875-2.4061z"
            fill="#fff"
          />
          <path
            d="m8.1774 24.833h2.1886l0.8156-1.74h1.7264l-0.2991 1.74h2.0663l1.4001-9.6516h-2.8954l-5.0025 9.6516zm5.0568-3.5616h-1.1826l1.4545-3.0993c0.2311-0.4758 0.4078-0.9108 0.4758-1.0739-0.0272 0.1767-0.1359 0.7068-0.2175 1.1554l-0.5302 3.0178z"
            fill="#fff"
          />
          <path
            d="m20.422 15.181h-2.3382l-3.2081 9.6516h1.9711l1.6584-5.0433c0.1768-0.503 0.2991-0.9515 0.3535-1.1147-0.0136 0.1767-0.068 0.7205-0.0952 1.1691l-0.299 4.9889h2.2837l3.2081-9.6516h-1.9711l-1.5768 4.7986c-0.1768 0.503-0.2991 0.9516-0.3535 1.1147 0.0136-0.1767 0.0544-0.7205 0.0816-1.169l0.2855-4.7443z"
            fill="#fff"
          />
          <path
            d="m26.331 21.19-0.0408 0.1223c-0.2991 0.9108-0.7884 1.9303-1.3866 1.9303-0.6796 0-0.4214-1.6176 0.1496-3.3576 0.802-2.4469 1.2914-3.1402 1.8487-3.1402 0.3127 0 0.5166 0.3127 0.5846 1.4138l1.9167-0.6389c-0.0816-1.4002-0.7477-2.5285-2.2974-2.5285-1.7808 0-3.1945 1.5089-4.1868 4.5403-0.9108 2.7868-0.8293 5.3696 1.0875 5.3696 0.666 0 1.1282-0.2583 1.604-0.8428l-0.1359 0.7748h1.5361l1.8215-5.4647h-2.9362l-0.5981 1.8216h1.0331z"
            fill="#fff"
          />
          <path
            d="m30.898 25.009c1.672 0 3.1401-1.4953 4.1189-4.5267 0.9107-2.7867 0.8971-5.4783-1.7264-5.4783-1.6721 0-3.1402 1.4953-4.119 4.5267-0.9107 2.7868-0.8971 5.4783 1.7265 5.4783zm0.2175-1.7264c-0.8157 0-0.3399-1.7944 0.1903-3.3984 0.802-2.4877 1.305-3.1538 1.7672-3.1538 0.802 0 0.3398 1.7672-0.1904 3.3985-0.802 2.4876-1.305 3.1537-1.7671 3.1537z"
            fill="#fff"
          />
        </g>
      </g>
      <defs>
        <clipPath id="b">
          <rect width="40" height="40" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const YFill = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" {...props}>
      <path
        d="m0.5 10c0-5.5228 4.4772-10 10-10 5.5228 0 10 4.4772 10 10 0 5.5228-4.4772 10-10 10-5.5228 0-10-4.4772-10-10z"
        fill="#FC3F1D"
      />
      <path
        d="m10.771 11.033c0.5843 1.28 0.779 1.7251 0.779 3.2625v2.0383h-2.0868v-3.4365l-3.9375-8.5635h2.1775l3.0678 6.6992zm2.5738-6.6992-2.553 5.8017h2.1217l2.56-5.8017h-2.1287z"
        fill="#fff"
      />
    </svg>
  );
};
