import type { Location } from "history";
import { matchPath } from "react-router-dom";

export const infoLink = () => "/info";
export const infoDetailsLink = (pageName: string) => `/info/${pageName}`;
export const infoParams = (location: Location) => {
  const match = matchPath("/info/:id", location.pathname);

  return {
    detailId: match?.params.id,
  };
};
