/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useEffect, useMemo, useState, type ReactNode } from "react";
import { v4 as uuidv4 } from "uuid";

import { cssFns } from "@superweb/css";
import { useTypo, useUiColors } from "@superweb/ui";

import { Message, useMessage } from "#internal/intl";
import { useUploadFile } from "#internal/api/media-storage/v2_media";
import type { LicenseCountryISO } from "#internal/api/hiring-candidates/definitions";
import type { HttpError } from "#internal/api/fetch";

import { Attach } from "./attach";

const MAX_FILE_SIZE_MB = 20;
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024; // 20MB

export type fileFieldState = { id: string; version: string };

export const UploadField = ({
  setData,
  title,
  id,
  version,
  countryCode,
  formats,
}: {
  setData: ({
    data,
    errorData,
  }: {
    data?: fileFieldState;
    errorData?: HttpError;
  }) => void;
  title: string;
  id: string;
  version: string;
  countryCode: LicenseCountryISO;
  formats: string[];
}) => {
  const uiColors = useUiColors();
  const typo = useTypo();
  const message = useMessage();
  const [file, setFile] = useState<File>();
  const [isReady, setIsReady] = useState(false);
  const [idempotencyToken] = useState(() => uuidv4());

  const { mutate, isLoading, isError } = useUploadFile({
    onSuccess: (data) => {
      setData({ data });
    },
    onSettled: () => {
      setIsReady(true);
    },
    onError: (errorData) => {
      setData({ errorData });
    },
  });

  const invalidFormat = file && !formats.includes(file.type);

  useEffect(() => {
    setData({ data: { id: "", version: "" } });
    if (file && file.size <= MAX_FILE_SIZE && !invalidFormat) {
      setIsReady(false);
      mutate({
        file,
        id,
        version,
        source: "partners-app",
        origin: countryCode,
        type: "scout-identity-card",
        idempotencyToken,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, mutate]);

  const blobUrl = useMemo(
    () => (file ? URL.createObjectURL(new Blob([file])) : undefined),
    [file],
  );

  const showErrorMessage =
    file && file.size <= MAX_FILE_SIZE && isReady && isError;

  return (
    <div css={{ display: "grid", position: "relative" }}>
      <div
        css={{
          display: "grid",
          backgroundColor: uiColors.controlMinor,
          position: "relative",
          ...cssFns.border({ radius: "16px" }),
          ...cssFns.overflow("hidden"),
          color: file ? uiColors.background : uiColors.text,
        }}
      >
        <div
          css={{
            ...typo({
              level: "body2",
              weight: "medium",
              density: "tight",
            }),
            position: "relative",
            zIndex: "1",
            pointerEvents: "none",
            ...cssFns.padding("11px", "12px"),
          }}
        >
          {title}
        </div>
        <div
          css={{
            paddingBlockEnd: "41%",
          }}
        />
        {blobUrl && (
          <div
            css={{
              position: "absolute",
              ...cssFns.inset("0"),
            }}
          >
            <div
              css={{
                position: "absolute",
                ...cssFns.inset("0"),
                backgroundColor: uiColors.fog,
              }}
            />
            <div
              css={{
                position: "absolute",
                insetInlineStart: "0",
                insetBlockStart: "0",
                width: "100%",
                insetBlockEnd: "48px",
                backgroundImage: cssFns.linearGradient("180deg", [
                  "rgba(0, 0, 0, 0.4) 0%",
                  "rgba(0, 0, 0, 0) 100%",
                ]),
              }}
            />
            <img
              src={blobUrl}
              css={{
                objectFit: "cover",
                width: "100%",
                height: "100%",
              }}
            />
          </div>
        )}
        <Attach
          label={message({
            id: "a66be30d-f249-4d3b-9288-d72a9e20db12",
            context: "Attach file. Accessibility label",
            default: "Attach file",
          })}
          onChange={setFile}
          isFileAttached={Boolean(file)}
          view="ghost"
          accept={formats.join(",")}
          isLoading={isLoading}
        />
      </div>
      {file && file.size > MAX_FILE_SIZE ? (
        <ErrorMessage>
          <Message
            id="c3eaa6e9-2aa4-4d86-bbe2-f0c61402fb78"
            context="Image attach, maximum file size exceeded"
            default="Maximum file size of {maxSize}MB exceeded"
            values={{ maxSize: MAX_FILE_SIZE_MB }}
          />
        </ErrorMessage>
      ) : (
        invalidFormat && (
          <ErrorMessage>
            <Message
              id="b2211633-90e3-4ff3-a903-88af04c27b2e"
              context="Image attach, invalid format"
              default="Invalid format"
            />
          </ErrorMessage>
        )
      )}
      {showErrorMessage && (
        <ErrorMessage>
          <Message
            id="c3255e1c-8035-4082-baf3-76c39ed23906"
            context="Upload file failed status"
            default="Failed to send file"
          />
        </ErrorMessage>
      )}
    </div>
  );
};

const ErrorMessage = ({ children }: { children: ReactNode }) => {
  const typo = useTypo();
  const uiColors = useUiColors();

  return (
    <div
      css={{
        ...typo({
          level: "caption2",
          weight: "regular",
          density: "normal",
        }),
        color: uiColors.error,
        ...cssFns.padding("6px", "12px"),
        position: "absolute",
        insetBlockStart: "100%",
      }}
    >
      {children}
    </div>
  );
};
