/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { useLocale } from "@superweb/intl";

import {
  getReferralIdFromLocation,
  referralAltCheckLink,
} from "#internal/routes";
import { getPassportUrl } from "#internal/utils/passport";
import { triggerLink } from "#internal/utils/triggerLink";

import { BaseLayout } from "./base-layout";
import { WelcomeContent, WelcomeHeaderComponent } from "./welcome";

export const RedirectToPassport = ({
  mode,
  onLanguageChange,
}: {
  mode: "login" | "check";
  onLanguageChange?: (language?: string) => void;
}) => {
  const location = useLocation();
  const { referralId } = getReferralIdFromLocation(location);
  const locale = useLocale();
  const [searchParams] = useSearchParams();
  const language = searchParams.get("locale") ?? locale.language;

  useEffect(() => {
    onLanguageChange?.(language);
  }, [language, onLanguageChange]);

  const redirectToPassport = () => {
    if (referralId && mode === "check") {
      const passportAuthUrl = getPassportUrl({
        lang: language,
        retpath: `${window.location.origin}${referralAltCheckLink(referralId)}?locale=${language}`,
      });
      triggerLink(passportAuthUrl, { target: "_self" });
    }

    if (referralId && mode === "login") {
      const passportAuthUrl = getPassportUrl({
        lang: language,
        retpath: window.location.href,
      });
      triggerLink(passportAuthUrl, { target: "_self" });
    }
  };

  // Нужно редиректить на welcome экран, иначе будет пустой экран?
  if (!referralId) return null;

  return (
    <BaseLayout headerComponent={<WelcomeHeaderComponent />}>
      <WelcomeContent redirectToPassport={redirectToPassport} />
    </BaseLayout>
  );
};
