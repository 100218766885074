import { MessageCache, createMessages } from "@superweb/intl";

export const { Messages, Message, useMessage } = createMessages({
  cache: new MessageCache({
    key: ({ baseName }: Intl.Locale) => baseName,
    load: (language) => {
      return import(`./translations/${language}.json`)
        .then((module) => module.default)
        .catch(() => undefined);
    },
  }),
  sourceLanguage: "en",
});

export type Language = "ru" | "en" | "fr" | "sw" | "es-419" | "pt-PT" | "vi";

export const availableLocales: Language[] = [
  "ru",
  "en",
  "fr",
  "pt-PT",
  "sw",
  "es-419",
  "vi",
];

export const isValidLanguage = (language?: string): language is Language => {
  if (language === undefined) return false;

  return (availableLocales as string[]).includes(language);
};
