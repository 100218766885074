/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import {
  createContext,
  useState,
  type Dispatch,
  type SetStateAction,
  useMemo,
  useContext,
  type ReactNode,
} from "react";

import type { Values } from "./types";
import { useValidate, type ValidationErrors } from "./useValidate";

const FormContext = createContext<{
  formValues: Values;
  setFormValues: Dispatch<SetStateAction<Values>>;
  staticErrors: ValidationErrors;
  isValid: boolean;
}>({
  formValues: {},
  setFormValues: () => {},
  staticErrors: {},
  isValid: false,
});

export const useFormContext = () => useContext(FormContext);

export const ReferralFormProvider = ({ children }: { children: ReactNode }) => {
  const [formValues, setFormValues] = useState<Values>({});

  const { staticErrors, isValid } = useValidate(formValues);

  const providerValue = useMemo(
    () => ({
      formValues,
      setFormValues,
      staticErrors,
      isValid,
    }),
    [formValues, setFormValues, staticErrors, isValid],
  );

  return (
    <FormContext.Provider value={providerValue}>
      {children}
    </FormContext.Provider>
  );
};
