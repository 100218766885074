/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { Link } from "react-router-dom";

import { NavMenu, icons, useIsMobile } from "@superweb/ui";
import { useFlag } from "@superweb/flags";

import {
  agentsLink,
  leadsLink,
  tariffsLink,
  billingLink,
  usersLink,
  generateReferralLinkLink,
  activationListLink,
  infoLink,
  teamleadDashboardLink,
  settingsLink,
  useMakeLink,
} from "#internal/routes";
import logo from "#internal/ui/logo.png";
import { useMessage } from "#internal/intl";

export const Menu = () => {
  const message = useMessage();
  const flag = useFlag();
  const isMobile = useIsMobile();
  const makeLink = useMakeLink();

  const mainPageLink = flag({
    id: "7c258246-8d5f-443c-ba78-1bc02282ad19",
    description: "Dashboard should navigate to /leads",
  })
    ? leadsLink()
    : agentsLink();

  const logoImg = <img src={logo} css={{ width: "40px" }} />;

  return (
    <NavMenu
      appName={message({
        id: "bb84a8a6-687f-4187-9340-94c74805bdf7",
        context: "Menu. App title",
        default: "Partner account",
      })}
      logo={isMobile ? logoImg : <Link to={mainPageLink}>{logoImg}</Link>}
      primaryItems={[
        isMobile && {
          type: "link" as const,
          icon: <icons.ArrowDashboard />,
          label: message({
            id: "3f192772-de2c-41de-aeca-d9ece3385421",
            context: "App menu. Menu item title",
            default: "Dashboard",
          }),
          link: makeLink(mainPageLink),
        },

        flag({
          id: "e2ed57c3-e6b2-4ea0-9315-310d9f17b216",
          description: "Show tariffs link at menu",
        }) && {
          type: "link" as const,
          icon: <icons.UserFill />,
          label: message({
            id: "727dd61e-b973-4b4d-a0b6-3689caf967d7",
            context: "App menu. Menu item title",
            default: "Rates",
          }),
          link: makeLink(tariffsLink()),
        },

        flag({
          id: "0a0522e0-a497-4d9b-8140-ad31124f3d03",
          description: "Show billing page link",
        }) && {
          type: "link" as const,
          icon: <icons.WalletOutline />,
          label: message({
            id: "e49ec966-988c-41db-bf30-339fac592910",
            context: "App menu. Menu item title",
            default: "Billing",
          }),
          link: makeLink(billingLink()),
        },

        flag({
          id: "aceabd4c-3356-4e10-97a3-50f029388e75",
          description: "Show users page link at menu",
        }) && {
          type: "link" as const,
          icon: <icons.LockFill />,
          label: message({
            id: "ab957f3f-df4c-479a-b618-0890a888b258",
            context: "App menu. Menu item title",
            default: "Users",
          }),
          link: makeLink(usersLink()),
        },

        flag({
          id: "0c75c29b-7a63-417f-8694-d4e6579d2c4f",
          description: "Show generate ref button at menu",
        }) && {
          type: "link" as const,
          icon: <icons.Link />,
          label: message({
            id: "8d9e4216-a245-43d6-8326-ed51ce05b7d6",
            context: "App menu. Menu item title",
            default: "Referral link",
          }),
          link: makeLink(generateReferralLinkLink()),
        },

        flag({
          id: "89e65d8f-560e-42bc-adce-df1652cc8cb4",
          description: "Show activation list button at menu",
        }) && {
          type: "link" as const,
          icon: <icons.Check />,
          label: message({
            id: "a12ee388-2ddb-496c-99b0-0c8593d9c94a",
            context: "App menu. Menu item title",
            default: "Activation link",
          }),
          link: makeLink(activationListLink()),
        },

        flag({
          id: "12059bf3-4b3f-434d-87c0-ef1361a356be",
          description: "Show eats info link at menu",
        }) && {
          type: "link" as const,
          icon: <icons.BookShelf />,
          label: message({
            id: "81d6f779-0a68-4a57-93cd-9916ff3b0a07",
            context: "App menu. Menu item title",
            default: "Information",
          }),
          link: makeLink(infoLink()),
        },

        flag({
          id: "e727c28a-9d00-4b4d-9fa6-c70e6ba15b86",
          description: "Show my team link at menu",
        }) && {
          type: "link" as const,
          icon: <icons.UsersFill />,
          label: message({
            id: "95529363-46f5-4cb0-a53d-eabf6f8db441",
            context: "App menu. Menu item title",
            default: "My team",
          }),
          link: makeLink(teamleadDashboardLink()),
        },
      ]}
      secondaryItems={[
        {
          type: "link",
          icon: <icons.Settings2Fill />,
          label: message({
            id: "37a66a06-cf23-4872-a50d-5ac37c8cd024",
            context: "App menu. Menu item title",
            default: "Settings",
          }),
          link: makeLink(settingsLink()),
        },
      ]}
    />
  );
};
