/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { focusWithoutScrolling } from "@react-aria/utils";
import {
  useButton,
  useFocusRing,
  useHover,
  mergeProps,
  type AriaButtonProps,
  useLink,
} from "react-aria";
import {
  useRef,
  type ComponentType,
  type MutableRefObject,
  type PointerEvent,
} from "react";

import { cssFns, useCss, useKeyframes } from "@superweb/css";

import { useMessage } from "#intl";

import { useUiColors, useUiShadows } from "../theme";
import { useTypo } from "../typo";
import { icons } from "../icons";
import { Tooltip, useTooltip } from "../tooltip";

export type View =
  | "default"
  | "action"
  | "floating"
  | "contrast"
  | "outline"
  | "contrastOutline"
  | "ghost";
export type Size = "l" | "m" | "s" | "xs";
export type Shape = "circle" | "squircle";

/**
 * @internal For usage only through wrapper components
 */
export const InternalButton = ({
  ariaLabel,
  disabled = false,
  iconEnd: IconEnd,
  icon: Icon,
  onPress,
  progress = false,
  shape = "squircle",
  size = "m",
  subText,
  text,
  type = "button",
  view = "default",
  buttonRef,
  ariaButtonProps,
  link,
  tooltip,
  tooltipOptions,
}: {
  disabled?: boolean;
  onPress?: () => void;
  progress?: boolean;
  shape?: Shape;
  size?: Size;
  type?: "button" | "submit";
  view?: View;
  text?: string;
  subText?: string;
  iconEnd?: ComponentType<{ className?: string }>;
  icon?: ComponentType<{ className?: string }>;
  ariaLabel?: string;
  link?: {
    href: string;
    onClick?: (e: PointerEvent<HTMLAnchorElement>) => void;
    external?: boolean;
    download?: boolean;
  };
  buttonRef?: MutableRefObject<null>;
  ariaButtonProps?: AriaButtonProps<"button">;
  tooltip?: string;
  tooltipOptions?: {
    variant?: "default" | "info";
    placement?: "top" | "bottom" | "start" | "end";
  };
}) => {
  const message = useMessage();
  const ref = useRef(null);
  const internalRef = buttonRef || ref;

  const { linkProps, isPressed: isLinkPressed } = useLink(
    {
      isDisabled: disabled,
      "aria-label": ariaLabel,
      onPress: (e) => {
        if (!progress) {
          ariaButtonProps?.onPress?.(e);
          onPress?.();
        }
      },
    },
    internalRef,
  );

  const { buttonProps, isPressed: isButtonPressed } = useButton(
    {
      ...mergeProps(ariaButtonProps, {
        type,
        isDisabled: disabled,
        "aria-label": progress
          ? message({
              id: "01cbe698-49ee-4b20-85d0-4611d1583b71",
              context:
                "Button. Loading label (invisible, made for accessibility)",
              default: "Loading",
            })
          : ariaLabel,
      }),
      onPress: (e) => {
        if (!progress) {
          // Workaround for https://github.com/adobe/react-spectrum/issues/4355
          // @ts-expect-error e.target is not known to be a FocusableElement here, but it is because of useFocus
          focusWithoutScrolling(e.target);
          ariaButtonProps?.onPress?.(e);
          onPress?.();
        }
      },
      // Workaround for https://github.com/adobe/react-spectrum/issues/4355
      // @ts-expect-error https://github.com/adobe/react-spectrum/blob/f6e686fe9d3b983d48650980c1ecfdde320bc62f/packages/%40react-aria/button/src/useButton.ts#L58
      preventFocusOnPress: true,
    },
    internalRef,
  );
  const { hoverProps, isHovered } = useHover({
    isDisabled: disabled,
  });
  const { focusProps, isFocusVisible: isFocused } = useFocusRing();
  const {
    triggerProps,
    tooltipProps: tooltipTriggerProps,
    state: tooltipState,
  } = useTooltip(internalRef);

  const css = useCss();
  const typo = useTypo();
  const uiColors = useUiColors();
  const uiShadows = useUiShadows();
  const keyframes = useKeyframes();

  const isPressed = link ? isLinkPressed : isButtonPressed;
  const transparent = cssFns.transparentize(uiColors.everBack, 1);

  const baseColor = {
    default: uiColors.controlMinor,
    action: uiColors.controlMain,
    outline: progress ? uiColors.background : transparent,
    contrastOutline: transparent,
    floating: uiColors.background,
    contrast: uiColors.everFront,
    ghost: transparent,
  }[view];

  const textColor = {
    default: uiColors.text,
    action: uiColors.textOnControlMain,
    outline: uiColors.text,
    contrastOutline: uiColors.everFront,
    floating: uiColors.text,
    contrast: uiColors.everBack,
    ghost: uiColors.text,
  }[view];

  const backgroundColor =
    !(disabled || progress) && (isPressed || isHovered)
      ? cssFns.blend(baseColor, isPressed ? uiColors.press : uiColors.hover)
      : baseColor;

  const iconSpacing = { l: "16px", m: "12px", s: "8px", xs: "6px" }[size];
  const textSpacing = { l: "18px", m: "16px", s: "14px", xs: "12px" }[size];

  const iconSize = size === "xs" ? "20px" : "24px";

  let gridTemplateColumns = "";

  if (Icon && text && IconEnd) {
    gridTemplateColumns = `${iconSpacing} [icon] ${iconSize} auto 4px [text] auto 4px [iconEnd] ${iconSize} auto ${iconSpacing}`;
  } else if (Icon && text) {
    gridTemplateColumns = `${iconSpacing} [icon] ${iconSize} auto 4px [text] auto ${textSpacing}`;
  } else if (text && IconEnd) {
    gridTemplateColumns = `${textSpacing} [text] auto 4px [iconEnd] ${iconSize} auto ${textSpacing}`;
  } else if (Icon && IconEnd) {
    gridTemplateColumns = `${iconSpacing} [icon] ${iconSize} auto ${iconSpacing} [iconEnd] ${iconSize} auto ${iconSpacing}`;
  } else if (Icon) {
    gridTemplateColumns = `${iconSpacing} [icon] ${iconSize} auto ${iconSpacing}`;
  } else if (text) {
    gridTemplateColumns = `${textSpacing} [text] auto ${textSpacing}`;
  } else if (IconEnd) {
    gridTemplateColumns = `auto ${iconSpacing} [iconEnd] ${iconSize} auto ${iconSpacing}`;
  }

  const Content = (
    <span
      css={{
        display: "grid",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        position: "relative",
        isolation: "isolate",
        gridTemplateColumns,
        gridTemplateRows: "1fr [content] auto 1fr",
        ...cssFns.overflow("hidden"),
        ...cssFns.border({
          width:
            view === "outline" || view === "contrastOutline" ? "0.5px" : "0",
          style:
            view === "outline" || view === "contrastOutline" ? "solid" : "none",
          color:
            view === "contrastOutline" ? uiColors.everFront : uiColors.line,
          radius:
            shape === "circle"
              ? "100px"
              : size === "xs"
                ? "10px"
                : size === "s"
                  ? "13px"
                  : "16px",
        }),
        boxShadow: `${
          view === "floating" && !disabled
            ? uiShadows.bottomNormal
            : "0 0 0 0 transparent"
        }, ${
          isFocused && !disabled
            ? "inset 0 0 0 2px " + uiColors.focus
            : "0 0 0 0 transparent"
        }`,
        cursor: disabled ? "default" : "pointer",
        height: { l: "56px", m: "48px", s: "40px", xs: "32px" }[size],
        backgroundColor,
        color: textColor,
        opacity: disabled ? "0.5" : "1",
        outlineStyle: "none",
      }}
    >
      {Icon && (
        <Icon
          className={css({
            gridColumnStart: "icon",
            gridRowStart: "content",
            width: iconSize,
            height: iconSize,
            display: "flex",
            alignItems: "stretch",
            justifyItems: "stretch",
            opacity: progress ? "0" : "1",
          })}
        />
      )}
      {text && (
        <span
          aria-hidden={progress ? "true" : undefined}
          css={{
            ...typo({
              level: size === "xs" ? "caption1" : "body2",
              weight: view === "action" ? "medium" : "regular",
              density: "tight",
            }),
            ...cssFns.overflow("hidden"),
            gridColumnStart: "text",
            gridRowStart: "content",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            opacity: progress ? "0" : "1",
          }}
        >
          {text}
          {subText && (
            <span
              css={{
                ...cssFns.overflow("hidden"),
                gridColumnStart: "text",
                gridRowStart: "content",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                ...typo({
                  level: size === "xs" ? "caption2" : "caption1",
                  weight: "regular",
                  density: "tight",
                }),
                display: "block",
              }}
            >
              {subText}
            </span>
          )}
        </span>
      )}
      {IconEnd && (
        <IconEnd
          className={css({
            gridColumnStart: "iconEnd",
            gridRowStart: "content",
            width: iconSize,
            height: iconSize,
            display: "flex",
            alignItems: "stretch",
            justifyItems: "stretch",
            opacity: progress ? "0" : "1",
          })}
        />
      )}

      {progress && (
        <span
          css={{
            gridColumnStart: "1",
            gridColumnEnd: "-1",
            gridRowStart: "1",
            gridRowEnd: "-1",
            alignSelf: "stretch",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            animationDuration: "1s",
            animationTimingFunction: "linear",
            animationIterationCount: "infinite",
            animationName: keyframes({
              0: {
                transform: "rotate(0deg)",
              },
              100: {
                transform: "rotate(360deg)",
              },
            }),
          }}
        >
          <icons.Spinner width={iconSize} height={iconSize} />
        </span>
      )}
      {tooltip && tooltipState.isOpen && (
        <Tooltip
          state={tooltipState}
          tooltipProps={tooltipTriggerProps}
          targetRef={ref}
          variant={tooltipOptions?.variant}
          placement={tooltipOptions?.placement}
        >
          {tooltip}
        </Tooltip>
      )}
    </span>
  );

  if (link) {
    const linkStyles = {
      display: "inline-block",
      ...cssFns.margin("0"),
      ...cssFns.padding("0"),
      ...cssFns.border({ style: "none" }),
      outlineStyle: "none",
      backgroundColor: "transparent",
      textAlign: "center",
      textDecorationLine: "none",
    };

    return disabled ? (
      <span
        {...mergeProps(linkProps, hoverProps, focusProps, triggerProps)}
        ref={internalRef}
        css={linkStyles}
      >
        {Content}
      </span>
    ) : (
      <a
        {...mergeProps(linkProps, hoverProps, focusProps, triggerProps)}
        ref={internalRef}
        css={linkStyles}
        href={link.href}
        onClick={link.onClick}
        download={link.download}
        {...(link.external && { target: "_blank", rel: "noreferrer" })}
      >
        {Content}
      </a>
    );
  }

  return (
    <button
      {...mergeProps(buttonProps, hoverProps, focusProps, triggerProps)}
      ref={internalRef}
      css={{
        // Button element cannot be reliably styled in Safari < 14.
        // Hence we use it only as a semantic container with reset styling
        // and mark up the inner layout using span.
        appearance: "none",
        ...cssFns.margin("0"),
        ...cssFns.padding("0"),
        ...cssFns.border({ style: "none" }),
        outlineStyle: "none",
        backgroundColor: "transparent",
      }}
    >
      {Content}
    </button>
  );
};
