import type { Location } from "history";
import { matchPath } from "react-router-dom";

export const agentsLink = () => "/agents";

export const leadLink = (id: string) => `/agents/lead/${id}`;

export const leadParams = (location: Location) => {
  const match = matchPath("/agents/lead/:id", location.pathname);

  return {
    leadId: match?.params.id,
  };
};
