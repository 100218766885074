import { Css } from "../css";

export const wrap = (jsx: any): any => {
  return (type: any, config: any, maybeKey: any, ...rest: any) => {
    if (
      typeof type !== "string" ||
      !config ||
      !(
        config.css ||
        config.__experimental_placeholderCss ||
        config.__experimental_webkitScrollbarCss ||
        config.__experimental_webkitScrollbarCornerCss ||
        config.__experimental_webkitScrollbarButtonCss ||
        config.__experimental_webkitScrollbarThumbCss ||
        config.__experimental_webkitScrollbarThumbHoverCss ||
        config.__experimental_webkitSearchCancelButtonCss ||
        config.__experimental_webkitSearchDecorationCss ||
        config.__experimental_beforeCss ||
        config.__experimental_afterCss
      )
    ) {
      return jsx(type, config, maybeKey, ...rest);
    }

    const {
      css,
      __experimental_placeholderCss,
      __experimental_webkitScrollbarCss,
      __experimental_webkitScrollbarCornerCss,
      __experimental_webkitScrollbarButtonCss,
      __experimental_webkitScrollbarThumbCss,
      __experimental_webkitScrollbarThumbHoverCss,
      __experimental_webkitSearchCancelButtonCss,
      __experimental_webkitSearchDecorationCss,
      __experimental_beforeCss,
      __experimental_afterCss,
      key,
      children,
      ...props
    } = config;

    return jsx(
      Css,
      {
        css,
        __experimental_placeholderCss,
        __experimental_webkitScrollbarCss,
        __experimental_webkitScrollbarCornerCss,
        __experimental_webkitScrollbarButtonCss,
        __experimental_webkitScrollbarThumbCss,
        __experimental_webkitScrollbarThumbHoverCss,
        __experimental_webkitSearchCancelButtonCss,
        __experimental_webkitSearchDecorationCss,
        __experimental_beforeCss,
        __experimental_afterCss,
        key,
        type,
        props,
        children,
      },
      maybeKey,
      ...rest,
    );
  };
};
