/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import type { ComponentProps, ReactNode } from "react";

import { cssFns } from "@superweb/css";
import { useTypo } from "@superweb/ui";
import { useQueryErrorResetBoundary } from "@superweb/api";

import { Message } from "#internal/intl";

import { ErrorBoundary } from "./error-boundary";

const ErrorMessage = ({ message }: { message: string }) => {
  const typo = useTypo();

  return (
    <div
      css={{
        display: "grid",
        ...cssFns.placeContent("center"),
        minHeight: "100%",
      }}
    >
      <span
        css={typo({
          level: "title4",
          weight: "regular",
          density: "normal",
        })}
      >
        {message}
      </span>
    </div>
  );
};
export const ErrorFailedLoadFallback = () => (
  <div
    css={{
      ...cssFns.padding("20px", "16px"),
      display: "flex",
      flexDirection: "column",
    }}
  >
    <span>
      <Message
        id="901c9aba-ffcf-4fd0-920b-188e4a2552f1"
        context="Leads page. Lead details section. Error text"
        default="Failed to load data"
      />
    </span>
    <span>
      <Message
        id="ed456e9c-6ded-48d3-94a8-913265f73cd1"
        context="Leads page. Lead details section. Reload text"
        default="Try refreshing the page"
      />
    </span>
  </div>
);

export const Errors = ({
  children,
  fallbackRender,
  resetKey,
}: {
  children: ReactNode;
  fallbackRender?: ComponentProps<typeof ErrorBoundary>["fallbackRender"];
  resetKey?: ComponentProps<typeof ErrorBoundary>["resetKey"];
}) => {
  const { reset } = useQueryErrorResetBoundary();

  return (
    <ErrorBoundary
      onReset={reset}
      resetKey={resetKey}
      fallbackRender={
        fallbackRender ??
        (({ error }) => <ErrorMessage message={error.message} />)
      }
    >
      {children}
    </ErrorBoundary>
  );
};
