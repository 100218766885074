/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { InView } from "react-intersection-observer";

import { Spin } from "@superweb/ui";
import { cssFns, useCss } from "@superweb/css";

export const FetchMore = ({
  isFetching,
  onFetchMore,
}: {
  isFetching?: boolean;
  onFetchMore?: () => void;
}) => {
  const css = useCss();

  return (
    <InView
      className={css({
        display: "grid",
        ...cssFns.placeContent("center"),
        ...cssFns.padding("10px"),
      })}
      as="div"
      skip={isFetching}
      onChange={(inView: boolean) => {
        if (inView) {
          onFetchMore?.();
        }
      }}
    >
      <Spin size="m" />
    </InView>
  );
};
