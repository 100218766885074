import { cssFns } from "@superweb/css";
import { useUiOptions } from "../ui-options-context";
import { useUiColors } from "../theme";

export const useCustomScrollbar = () => {
  const uiColors = useUiColors();
  const uiOptions = useUiOptions();

  const enableCustomScrollbarInComponent =
    uiOptions.experimental?.enableCustomScrollbarInComponent;

  if (!enableCustomScrollbarInComponent || isFirefox()) {
    return {};
  }

  return {
    __experimental_webkitScrollbarCss: {
      width: "16px",
      height: "16px",
      backgroundColor: "transparent",
    },
    __experimental_webkitScrollbarThumbCss: {
      ...cssFns.border({
        width: "5px",
        style: "solid",
        color: "transparent",
        radius: "8px",
      }),
      backgroundColor: "transparent",
      boxSizing: "border-box",
      boxShadow: `inset 0 0 0 4px ${uiColors.hover}`,
    },
    __experimental_webkitScrollbarThumbHoverCss: {
      boxShadow: `inset 0 0 0 4px ${uiColors.press}`,
    },
    __experimental_webkitScrollbarCornerCss: {
      backgroundPositionX: "0",
      backgroundPositionY: "0",
    },
  };
};

const isFirefox = () => {
  return (
    /(Firefox)/i.test(navigator.userAgent) ||
    "MozAppearance" in document.documentElement.style ||
    (window as Window & typeof globalThis & { InstallTrigger: unknown })
      .InstallTrigger !== "undefined"
  );
};
