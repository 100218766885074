/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import type { Values } from "./types";
import { useValidators } from "./validation-helpers";

export type ValidationErrors = Partial<Record<keyof Values, string>>;

export const useValidate = (formValues: Values) => {
  const { validateRequired, validatePhone, validateTelegram } = useValidators();

  const staticErrors: ValidationErrors = {
    firstName: validateRequired(formValues.firstName),
    lastName: validateRequired(formValues.lastName),
    phone:
      validateRequired(formValues.phone) || validatePhone(formValues.phone!),
    idNumber: validateRequired(formValues.idNumber),
    country: validateRequired(formValues.country),
    photo: validateRequired(formValues.photo),
    selfie: validateRequired(formValues.selfie),
    telegram: validateTelegram(formValues.telegram),
  };

  const isValid = !Object.values(staticErrors).some(Boolean);

  return { staticErrors, isValid };
};
