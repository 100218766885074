// @ts-nocheck

import "@yandex-int/rum-counter/dist/bundle/send";
import "@yandex-int/rum-counter/dist/bundle/implementation";
import "@yandex-int/error-counter/dist/interfaceOverRum";
import "@yandex-int/error-counter/dist/implementation";
import "@yandex-int/error-counter/dist/filters";
import "@yandex-int/error-counter/dist/logError";
import "@yandex-int/error-counter/dist/logAjaxError";
import "@yandex-int/rum-counter/dist/inline/interface.d";

import { rumEnabled } from "./consts";

const parseCookie = (str) =>
  str
    .split(";")
    .map((v) => v.split("="))
    .reduce((acc, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
      return acc;
    }, {});

const cookie = parseCookie(document.cookie);

if (rumEnabled && typeof window.Ya.Rum.init === "function") {
  window.Ya.Rum.init(
    {
      clck: "https://yandex.ru/clck/click",
      reqid: `${Date.now()}:hiring-partners-app:${Math.random()}`,
      sendClientUa: true,
      sendUrlInResTiming: true,
    },
    {
      rum_id: "ru.hiring-partners-app",
      "-project": "hiring-partners-app",
      "-env": import.meta.env.VITE_PARTNERS_APP_MODE,
      "-version": import.meta.env.VERSION,
      "-host": window.location.host,
      "-page": window.location.pathname,
      "-uid": cookie.yandexuid,
      "-domain": window.location.host,
    },
  );
}

if (rumEnabled && typeof window.Ya.Rum.initErrors === "function") {
  window.Ya.Rum.initErrors({
    project: "hiring-partners-app",
    env: import.meta.env.VITE_PARTNERS_APP_MODE,
    host: window.location.host,
    page: window.location.pathname,
    referrer: true,
    resourceFails: true,
    uncaughtException: true,
    unhandledRejection: true,
    traceUnhandledRejection: true,
    yandexuid: cookie.yandexuid,
  });
}
