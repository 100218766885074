import { useInsertionEffect } from "react";
import { isInjected, inject } from "./instance";
import { dash, trim, hash, decl, name } from "./utils";
import { useCssOptions } from "./options";

export type FontFace = {
  src: string;
  ascentOverride?: string;
  descentOverride?: string;
  fontDisplay?: string;
  fontStretch?: string;
  fontStyle?: string;
  fontWeight?: string;
  fontVariant?: string;
  fontFeatureSettings?: string;
  fontVariationSettings?: string;
  lineGapOverride?: string;
  unicodeRange?: string;
};

const knownProperties = new Set(
  Object.keys({
    src: null,
    ascentOverride: null,
    descentOverride: null,
    fontDisplay: null,
    fontStretch: null,
    fontStyle: null,
    fontWeight: null,
    fontVariant: null,
    fontFeatureSettings: null,
    fontVariationSettings: null,
    lineGapOverride: null,
    unicodeRange: null,
  } satisfies {
    [K in keyof FontFace]-?: null;
  }),
);

/**
 * Apply `@font-face` atomically
 *
 * @example
 * ```tsx
 * const fontFace = useFontFace();
 *
 * <div
 *   css={{
 *     fontFamily: fontFace({
 *       src: `url(${fontUrl})`,
 *     }),
 *   }}
 * />
 * ```
 */
export const useFontFace = () => {
  const { nonce, dangerouslyAllowUnknownProperties } = useCssOptions();
  const rulesToBeInjected = new Map<string, string[]>();

  useInsertionEffect(() => {
    for (const [id, rules] of rulesToBeInjected) {
      if (!isInjected(id)) {
        inject({ id, rules, nonce });
      }
    }
  });

  return (...value: FontFace[]) => {
    const [className, rules] = composeRules(value, {
      dangerouslyAllowUnknownProperties,
    });
    rulesToBeInjected.set(className, rules);
    return className;
  };
};

const composeRules = (
  fontFaces: FontFace[],
  {
    dangerouslyAllowUnknownProperties,
  }: {
    dangerouslyAllowUnknownProperties: boolean | undefined;
  },
) => {
  let h = hash();
  const rules = [];
  for (const ff of fontFaces) {
    const entries = Object.entries(ff)
      .filter((e): e is [string, string] => Boolean(e[1]))
      .sort(([l], [r]) => (l < r ? -1 : l > r ? 1 : 0));
    let rule = "";
    for (const [property, value] of entries) {
      if (knownProperties.has(property) || dangerouslyAllowUnknownProperties) {
        const p = dash(property);
        const v = trim(value);
        h = hash(p, h);
        h = hash(v, h);
        rule += `${decl(p, v)};`;
      }
    }
    rules.push(rule);
  }
  const n = name(h);
  return [
    n,
    rules.map((rule) => `@font-face{font-family:${n};${rule}}`),
  ] as const;
};
