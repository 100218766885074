import { useQuery, type UseQueryOptions } from "@superweb/api";
import { fetch } from "./api/fetch";

type Version = {
  version?: string;
};

const getVersion = (source: Document = document): Version => {
  const versionMeta = source.head.querySelector('meta[name="app-version"]');
  const version = versionMeta
    ? versionMeta.getAttribute("content") ?? undefined
    : undefined;
  return { version };
};

export type UseRemoteVersionResult = Version;
export type UseRemoteVersionOptions = UseQueryOptions<UseRemoteVersionResult>;

export const useRemoteVersion = (options?: UseRemoteVersionOptions) => {
  const url = "/";
  return useQuery(
    [url],
    () => {
      return fetch(new Request(url), {
        ok: async (r) => {
          const data = await r.text();
          const parser = new DOMParser();
          const document = parser.parseFromString(data, "text/html");
          return getVersion(document);
        },
      });
    },
    options,
  );
};
