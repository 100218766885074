// https://a.yandex-team.ru/arcadia/taxi/backend-py3/services/hiring-api/docs/yaml/api/api.yaml

import { useQuery, type UseQueryOptions } from "@superweb/api";

import { fetch } from "#internal/api/fetch";
import { useLocale } from "@superweb/intl";

type ResponseCitiesSuggests = {
  id: string;
  city_local: string;
  city_eng: string;
  region_id: string;
}[];

export const useSuggestCities = (
  options?: UseQueryOptions<ResponseCitiesSuggests>,
) => {
  const { language: locale, baseName } = useLocale();

  const searchParams = new URLSearchParams({ locale });
  const url = `/api/v1/suggests/cities?${searchParams.toString()}`;

  return useQuery(
    [url],
    () => {
      return fetch(
        new Request(url, {
          method: "POST",
          credentials: "include",
          headers: {
            "Accept-Language": baseName,
          },
        }),
        { 200: (res) => res.json() as Promise<ResponseCitiesSuggests> },
      );
    },
    options,
  );
};

type CommonSuggestItem = { key: string; value: string };
export type UseCommonSuggestsResult = CommonSuggestItem[];
export type UseCommonSuggestsOptions = UseQueryOptions<UseCommonSuggestsResult>;

export const useCommonSuggest = (
  name: string,
  options?: UseCommonSuggestsOptions,
) => {
  const { language: locale, baseName } = useLocale();

  const searchParams = new URLSearchParams({ name, locale });
  const url = `/api/v1/suggests/common/?${searchParams.toString()}`;

  return useQuery(
    [url],
    () => {
      return fetch(
        new Request(url, {
          credentials: "include",
          headers: {
            "Accept-Language": baseName,
          },
        }),
        { 200: (res) => res.json() as Promise<UseCommonSuggestsResult> },
      );
    },
    options,
  );
};
