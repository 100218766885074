export class FetchError extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, FetchError.prototype);
    this.name = "FetchError";
  }
}

export class HttpError extends Error {
  response: Response;

  get status() {
    return this.response.status;
  }

  get requestId() {
    return this.response.headers.get("x-yarequestid") ?? undefined;
  }

  constructor(response: Response) {
    super(response.statusText);
    Object.setPrototypeOf(this, HttpError.prototype);
    this.name = "HttpError";
    this.response = response;
  }

  get traceId() {
    return this.response.headers.get("x-yatraceid") ?? undefined;
  }
}

type Key = number | "ok" | "clientError" | "serverError" | "error" | "any";

type Handler = (_: Response) => Promise<unknown>;

type Handlers = { [K in Key]?: Handler };

type Result<H> = H extends {
  [K in Key]?: (_: Response) => Promise<infer R>;
}
  ? R
  : never;

const pickHandler = (handlers: Handlers, status: number) => {
  if (status in handlers) {
    return handlers[status];
  }
  if (status >= 200 && status <= 299) {
    return handlers.ok;
  }
  if (status >= 400 && status <= 499) {
    return handlers.clientError ?? handlers.error;
  }
  if (status >= 500 && status <= 599) {
    return handlers.serverError ?? handlers.error;
  }
  return handlers.any;
};

const csrfTokenRequest = new Request("/api/csrf_token", {
  credentials: "include",
});
type CSRFTokenResponse = {
  sk: string;
  "max-age-seconds": number;
};

let tokenIssueDate: number | null = null;
const refreshToken = async () => {
  try {
    const csrfToken: CSRFTokenResponse = await globalThis
      .fetch(csrfTokenRequest)
      .then((res) => res.json());
    tokenIssueDate = Date.now();

    return csrfToken;
  } catch (e) {
    throw new FetchError(e instanceof Error ? e.message : undefined);
  }
};

const tokenIsExpired = (csrfToken: CSRFTokenResponse) => {
  if (!tokenIssueDate) {
    return true;
  }

  const now = Date.now();

  return (now - tokenIssueDate) / 1000 >= csrfToken["max-age-seconds"];
};

let csrfTokenCache: CSRFTokenResponse | null = null;
const getCSRFToken = async () => {
  if (!csrfTokenCache || tokenIsExpired(csrfTokenCache)) {
    csrfTokenCache = await refreshToken();
  }

  return csrfTokenCache.sk;
};

/**
 * Sends request and handles the response using provided handlers.
 *
 * Handlers is an object keyed by (in order of precedence):
 * - `number` — to match response status exactly
 * - `'ok' | 'clientError' | 'serverError'` — to match 2xx, 4xx and 5xx responses
 * - `'error'` — to match any non-2xx response
 * - `'any'` — to match any response
 *
 * Return value is a union type of return values of all handlers.
 *
 * If a successful response is received
 * and no matching handler is specified, a `TypeError` is thrown.
 *
 * If an unsuccessful response is received
 * and no matching handler is specified, an `HttpError` is thrown.
 */
export const fetch = async <H extends Handlers>(
  request: Request,
  handlers: H,
): Promise<Result<H>> => {
  const csrfToken = await getCSRFToken();
  request.headers.append("x-csrf-token", csrfToken);

  const response = await globalThis.fetch(request).catch((error: Error) => {
    throw new FetchError(error.message);
  });

  const handler = pickHandler(handlers, response.status);

  if (handler) {
    return handler(response) as Promise<Result<H>>;
  }

  if (!response.ok) {
    throw new HttpError(response);
  }

  throw TypeError(
    `fetch: handler for status ${response.status} is not specified`,
  );
};
