import type { Location } from "history";
import { matchPath } from "react-router-dom";

export const teamReferralCheckLink = (refId: string) =>
  `/team_ref/${refId}/complete`;

export const teamReferralCheckLinkParams = (location: Location) => {
  const match = matchPath("/team_ref/:teamRefId/complete", location.pathname);

  return {
    referralId: match?.params.teamRefId,
  };
};

export const teamReferralLinkParams = (location: Location) => {
  const match = matchPath("/team_ref/:teamRefId", location.pathname);

  return {
    referralId: match?.params.teamRefId,
  };
};
