/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import type { ReactNode } from "react";
import { useTypo, useUiColors, Button } from "@superweb/ui";
import { cssFns } from "@superweb/css";
import { useLocale } from "@superweb/intl";
import { useQueryErrorResetBoundary } from "@superweb/api";

import { Message, useMessage } from "#internal/intl";
import { HttpError } from "#internal/api/fetch";
import { Logo } from "#internal/ui";
import { ErrorBoundary } from "#internal/ui/error-boundary";

import { redirectToPassport } from "./utils/passport";
import { toCookieObject } from "./utils/toCookieObject";

const LoginPage = () => {
  const { language } = useLocale();
  const message = useMessage();

  return (
    <div
      css={{
        display: "grid",
        ...cssFns.placeContent("center"),
        rowGap: "32px",
        minHeight: "100vh",
      }}
    >
      <Logo showText />
      <Button
        view="action"
        onPress={() => redirectToPassport({ language })}
        text={message({
          id: "e07ace64-767c-4cdd-9f4e-e4f3c3e15105",
          context: "Agents. Auth page. Button text",
          default: "Log in to account",
        })}
      />
    </div>
  );
};

const UserNotFound = ({ requestId }: { requestId?: string }) => {
  const { language } = useLocale();
  const message = useMessage();
  const typo = useTypo();
  const uiColors = useUiColors();

  const { yandex_login } = toCookieObject();

  return (
    <div
      css={{
        display: "grid",
        ...cssFns.placeContent("center"),
        ...cssFns.placeItems("center"),
        rowGap: "32px",
        minHeight: "100vh",
      }}
    >
      <span
        css={typo({
          level: "title4",
          weight: "regular",
          density: "normal",
        })}
      >
        <Message
          id="418acf9d-411f-438d-a34d-59bb561b0613"
          context="Agents. Auth error page. Title"
          default="User does not exist"
        />
      </span>

      {yandex_login && (
        <div
          css={{
            color: uiColors.textMinor,
            ...typo({
              level: "body2",
              weight: "regular",
              density: "tight",
            }),
          }}
        >
          <Message
            id="b187bc81-c113-423a-8eda-936fb4bd9456"
            context="Error test"
            default="Login {login} is not registered"
            values={{ login: yandex_login }}
          />
        </div>
      )}

      <div
        css={{
          color: uiColors.textMinor,
          ...typo({
            level: "caption1",
            weight: "regular",
            density: "loose",
          }),
        }}
      >
        {requestId}
      </div>

      <Button
        view="action"
        onPress={() => redirectToPassport({ language })}
        text={message({
          id: "bf1b0ab1-8ca7-4bbb-b64a-74709e17e956",
          context: "Agents. Auth Error page. Button text",
          default: "Log in with another account",
        })}
      />
    </div>
  );
};

const ErrorMessage = ({ message }: { message: string }) => {
  const typo = useTypo();

  return (
    <div
      css={{
        display: "grid",
        alignContent: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <span
        css={typo({
          level: "title4",
          weight: "regular",
          density: "normal",
        })}
      >
        {message}
      </span>
    </div>
  );
};

export const HttpErrors = ({ children }: { children: ReactNode }) => {
  const { reset } = useQueryErrorResetBoundary();

  return (
    <ErrorBoundary
      onReset={reset}
      fallbackRender={({ error }) => {
        if (error instanceof HttpError) {
          switch (error.status) {
            case 401: {
              return <LoginPage />;
            }
            case 403: {
              return <UserNotFound requestId={error.requestId} />;
            }
            default:
              return <ErrorMessage message="Unexpected error" />;
          }
        }

        return null;
      }}
    >
      {children}
    </ErrorBoundary>
  );
};
