import React from "react";
import { Css } from "../css";

/**
 * @internal
 * @deprecated Do not use directly.
 * This function is only exported as a fallback
 * for automatic JSX runtime transform.
 * {@link https://legacy.reactjs.org/blog/2020/09/22/introducing-the-new-jsx-transform.html}
 */
export const createElement: unknown = (
  type: any,
  {
    css,
    __experimental_placeholderCss,
    __experimental_webkitScrollbarCss,
    __experimental_webkitScrollbarCornerCss,
    __experimental_webkitScrollbarButtonCss,
    __experimental_webkitScrollbarThumbCss,
    __experimental_webkitScrollbarThumbHoverCss,
    key,
    ...props
  }: any,
  ...children: any
) => {
  if (
    typeof type !== "string" ||
    !props ||
    !(
      props.css ||
      props.__experimental_placeholderCss ||
      props.__experimental_webkitScrollbarCss ||
      props.__experimental_webkitScrollbarCornerCss ||
      props.__experimental_webkitScrollbarButtonCss ||
      props.__experimental_webkitScrollbarThumbCss ||
      props.__experimental_webkitScrollbarThumbHoverCss
    )
  ) {
    return React.createElement(type, { ...props, key }, ...children);
  }
  return React.createElement(
    Css,
    {
      css,
      __experimental_placeholderCss,
      __experimental_webkitScrollbarCss,
      __experimental_webkitScrollbarCornerCss,
      __experimental_webkitScrollbarButtonCss,
      __experimental_webkitScrollbarThumbCss,
      __experimental_webkitScrollbarThumbHoverCss,
      key,
      type,
      props,
    },
    ...children,
  );
};
