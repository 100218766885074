import type { MenuGroup, SubMenuGroup } from "./menu";

export const isValidSubGroup = (subgroup: SubMenuGroup) => {
  return subgroup.items.some((item) => item);
};

export const isValidGroup = (group: MenuGroup) => {
  return group.items.some((subgroup) => {
    return subgroup ? isValidSubGroup(subgroup) : false;
  });
};
