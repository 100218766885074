import { triggerLink } from "./triggerLink";

const getPassportOrigin = () => {
  if (/\.yandex\.ru$/.test(window.location.hostname))
    return import.meta.env.VITE_PASSPORT_URL;
  if (/\.yango\.com$/.test(window.location.hostname))
    return import.meta.env.VITE_YANGO_PASSPORT_URL;

  throw Error("No passport url for current hostname");
};

export const getPassportUrl = (params: { retpath: string; lang?: string }) => {
  // PASSP-47272
  // Adds origin=yango_taxi for "yango" domain. It allow to get confirmation code in WhatsApp
  const searchParams = new URLSearchParams({
    ...(/\.yango\.com$/.test(window.location.hostname) && {
      origin: "yango_taxi",
    }),
    ...params,
  });

  return `${getPassportOrigin()}/auth?${searchParams}`;
};

export const redirectToPassport = ({ language }: { language: string }) => {
  const passportAuthUrl = getPassportUrl({
    retpath: window.location.origin,
    lang: language,
  });

  triggerLink(passportAuthUrl, { target: "_self" });
};
