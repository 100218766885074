/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import {
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  type MutableRefObject,
  type ReactNode,
  type SetStateAction,
} from "react";
import { createPortal } from "react-dom";
import { useId, useOverlay } from "react-aria";
import { PopoverContextProvider } from "../popover";
import { useSnackbarContext } from "../snackbar/snackbar";
import { useUiColors } from "../theme";
import { FloatingPanelContext } from "./floating-panel-context";

export const FloatingDialogContext = createContext<
  | {
      containerRef: MutableRefObject<HTMLDivElement | null>;
      dialogInstance: unknown;
      onDialogInstanceChange: (value: SetStateAction<unknown>) => void;
    }
  | undefined
>(undefined);

export const FloatingDialogContextProvider = FloatingDialogContext.Provider;

export const ExperimentalFloatingDialog = ({
  render,
  onClose,
}: {
  /**
   * Function which returns content for the dialog.
   * It is recommended to use the titleId to link to your title by id attribute,
   * in order to maintain accessibility.
   *
   * @example
   * ```
   * <FloatingDialog
   *  render={(titleId) => <div id={titleId}>Title</div>}
   * />
   * ```
   */
  render: (titleId: string) => ReactNode;
  /**
   * Called by pressing on:
   * * Backdrop
   * * ESC key
   *
   * Also it will be called if there is another component instance has been mounted.
   */
  onClose?: (trigger: "backdrop" | "esc" | "force") => void;
}) => {
  const floatingDialogContext = useContext(FloatingDialogContext);
  const [popoverContainer, setPopoverContainer] =
    useState<HTMLDivElement | null>(null);
  if (!floatingDialogContext)
    throw new Error(
      "Floating dialog must have an access to FloatingDialogContext",
    );
  const { containerRef, onDialogInstanceChange, dialogInstance } =
    floatingDialogContext;
  const [identity] = useState({});
  const dialogRef = useRef(null);
  const uiColors = useUiColors();
  const titleId = useId();

  useLayoutEffect(() => {
    onDialogInstanceChange(identity);
    return () => {
      onDialogInstanceChange((value: unknown) =>
        value === identity ? undefined : value,
      );
    };
  }, [identity, onDialogInstanceChange]);

  const mounted = useRef(false);

  const isCurrent = useMemo(() => {
    if (mounted.current) return dialogInstance === identity;
    return true;
  }, [dialogInstance, identity]);

  useEffect(() => {
    if (!mounted.current) mounted.current = true;
  }, []);

  useEffect(() => {
    if (isCurrent && onClose) {
      const onKeyDown = (e: KeyboardEvent) => {
        if (e.key !== "Escape" || e.altKey || e.ctrlKey || e.metaKey) {
          return;
        }

        onClose("esc");
      };
      document.addEventListener("keydown", onKeyDown, true);

      return () => {
        document.removeEventListener("keydown", onKeyDown, true);
      };
    }

    return undefined;
  }, [isCurrent, onClose]);

  useEffect(() => {
    if (!isCurrent) {
      onClose?.("force");
    }
  }, [isCurrent, onClose]);

  const isDismissible = Boolean(onClose);
  const snackbarContainerRef = useSnackbarContext();
  const { containerRef: floatingPanelContainerRef } =
    useContext(FloatingPanelContext);
  const { overlayProps, underlayProps } = useOverlay(
    {
      onClose: () => onClose?.("backdrop"),
      isKeyboardDismissDisabled: !isDismissible,
      isDismissable: isDismissible,
      isOpen: true,
      shouldCloseOnInteractOutside: (element) =>
        !snackbarContainerRef?.current?.contains(element) &&
        !floatingPanelContainerRef?.current?.contains(element),
    },
    dialogRef,
  );

  if (!containerRef.current || !isCurrent) {
    return null;
  }

  return createPortal(
    <PopoverContextProvider
      value={{
        container: popoverContainer,
      }}
    >
      <div
        css={{
          position: "fixed",
          backgroundColor: uiColors.fog,
          top: "0px",
          bottom: "0px",
          right: "0px",
          left: "0px",
        }}
        {...underlayProps}
      />
      <div
        {...overlayProps}
        role="dialog"
        aria-labelledby={titleId}
        ref={dialogRef}
        css={{
          display: "flex",
          alignItems: "flex-end",
          // BUG FIX: https://bugs.webkit.org/show_bug.cgi?id=140535
          isolation: "isolate",
        }}
      >
        {render(titleId)}
        <div ref={(element) => setPopoverContainer(element)} />
      </div>
    </PopoverContextProvider>,
    containerRef.current,
  );
};
