/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import type { Temporal } from "@js-temporal/polyfill";
import { cssFns, type Style } from "@superweb/css";
import { useLocale } from "@superweb/intl";
import { mergeProps, useFocusWithin, useHover, usePress } from "react-aria";
import { useUiColors, useUiShadows } from "../theme";
import { Button } from "../buttons/button";
import { icons } from "../icons";
import { useTypo } from "../typo";
import { useCalendar } from "./calendar";
import { DayCell, HeaderCell, MonthCell } from "./cell";
import type { CalendarProps, CalendarState } from "./types";

export type { CalendarUnit } from "./types";

const capitalize = (str: string) =>
  `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

const useHeaderStyle = (): Style => {
  const typo = useTypo();

  return {
    ...typo({
      level: "body2",
      weight: "regular",
      density: "tight",
    }),
    marginBlockEnd: "8px",
    display: "grid",
    gridTemplateColumns: "40px 1fr 40px",
    alignItems: "center",
    columnGap: "8px",
    textAlign: "center",
    justifyContent: "space-between",
    alignContent: "center",
  };
};

const ViewHeader = ({
  isDisabled,
  isPrevValid,
  isNextValid,
  text,
  onPrevClick,
  onNextClick,
  onClick,
}: {
  isDisabled: boolean;
  isPrevValid: boolean;
  isNextValid: boolean;
  text: string;
  onPrevClick?: () => void;
  onNextClick?: () => void;
  onClick?: () => void;
}) => {
  const headerStyle = useHeaderStyle();
  const locale = useLocale();

  return (
    <div css={headerStyle}>
      <Button
        view="outline"
        size="s"
        icon={
          locale.textInfo.direction === "rtl"
            ? icons.CornerRight
            : icons.CornerLeft
        }
        disabled={isDisabled || !isPrevValid}
        onPress={onPrevClick}
      />
      {onClick ? (
        <Button
          view="outline"
          size="s"
          disabled={isDisabled}
          onPress={onClick}
          text={text}
        />
      ) : (
        <span>{text}</span>
      )}

      <Button
        view="outline"
        size="s"
        icon={
          locale.textInfo.direction === "rtl"
            ? icons.CornerLeft
            : icons.CornerRight
        }
        disabled={isDisabled || !isNextValid}
        onPress={onNextClick}
      />
    </div>
  );
};

const DaysView = ({ state }: { state: CalendarState }) => {
  const {
    disabled,
    anchorValue,
    focusedValue,
    weeks,
    view,
    value,
    selectDate,
    isValid,
    setFocus,
    setDate,
    isPrevViewAvailable,
    goToPrevView,
  } = state;

  const rowStyle: Style = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const locale = useLocale();
  const previousDate = anchorValue.subtract({ months: 1 });
  const nextDate = anchorValue.add({ months: 1 });

  const { focusWithinProps } = useFocusWithin({
    onBlurWithin: () => setFocus(undefined),
  });

  const onClick = () => {
    if (isPrevViewAvailable()) {
      goToPrevView();
    }
  };

  return (
    <>
      <ViewHeader
        isDisabled={disabled}
        isPrevValid={isValid(previousDate, "month")}
        isNextValid={isValid(nextDate, "month")}
        onPrevClick={() => setDate(previousDate)}
        onNextClick={() => setDate(nextDate)}
        text={capitalize(
          anchorValue.toLocaleString(locale.toString(), {
            year: "numeric",
            month: "long",
          }),
        )}
        onClick={onClick}
      />
      <div css={rowStyle}>
        {weeks[0]?.map((date) => (
          <HeaderCell key={date.dayOfWeek} isDisabled={disabled} date={date} />
        ))}
      </div>
      <div
        css={{ display: "grid", rowGap: "2px", outlineStyle: "none" }}
        {...focusWithinProps}
      >
        {weeks.map((row, rowIndex) => (
          <div key={rowIndex} css={rowStyle}>
            {row.map((date) => {
              const isDisabled = disabled || !isValid(date, view);

              return (
                <DayCell
                  key={date.toString()}
                  value={date}
                  onPress={selectDate}
                  isSelected={value === undefined ? false : date.equals(value)}
                  isCurrentMonth={anchorValue.month === date.month}
                  isDisabled={isDisabled}
                  isFocused={
                    isDisabled || focusedValue === undefined
                      ? false
                      : date.equals(focusedValue)
                  }
                />
              );
            })}
          </div>
        ))}
      </div>
    </>
  );
};

const MonthsView = ({ state }: { state: CalendarState }) => {
  const {
    disabled,
    anchorValue,
    focusedValue,
    months,
    view,
    value,
    selectDate,
    isValid,
    setFocus,
    setDate,
  } = state;

  const previousDate = anchorValue.subtract({ years: 1 });
  const nextDate = anchorValue.add({ years: 1 });

  const { focusWithinProps } = useFocusWithin({
    onBlurWithin: () => setFocus(undefined),
  });

  return (
    <>
      <ViewHeader
        isDisabled={disabled}
        isPrevValid={isValid(previousDate, "month")}
        isNextValid={isValid(nextDate, "month")}
        onPrevClick={() => setDate(previousDate)}
        onNextClick={() => setDate(nextDate)}
        text={`${anchorValue.year}`}
      />
      <div
        {...focusWithinProps}
        css={{
          overflowY: "auto",
          gridTemplateColumns: "repeat(3, 1fr)",
          gridTemplateRows: "repeat(4, 70px)",
          boxSizing: "border-box",
          display: "grid",
        }}
      >
        {months.map((date) => {
          const isDisabled = disabled || !isValid(date, view);

          return (
            <MonthCell
              key={date.toString()}
              value={date}
              onPress={selectDate}
              isSelected={
                value === undefined
                  ? false
                  : date.month === value.month && date.year === value.year
              }
              isDisabled={isDisabled}
              isFocused={
                isDisabled || focusedValue === undefined
                  ? false
                  : date.month === focusedValue.month &&
                    date.year === focusedValue.year
              }
            />
          );
        })}
      </div>
    </>
  );
};

type Preset = {
  label: string;
  date: Temporal.PlainDate;
};

const Preset = ({
  label,
  date,
  onPress,
}: {
  onPress: (date: Temporal.PlainDate) => void;
} & Preset) => {
  const uiColors = useUiColors();
  const typo = useTypo();

  const { pressProps } = usePress({ onPress: () => onPress(date) });
  const { isHovered, hoverProps } = useHover({});

  return (
    <div
      {...mergeProps(pressProps, hoverProps)}
      css={{
        cursor: "pointer",
        marginInlineEnd: "4px",
        marginBlockStart: "8px",
        backgroundColor: isHovered
          ? uiColors.backgroundMinor
          : uiColors.background,
        ...typo({
          level: "caption1",
          weight: "regular",
          density: "tight",
        }),
        ...cssFns.padding("9px", "16px"),
        ...cssFns.border({
          color: uiColors.line,
          radius: "10px",
          style: "solid",
          width: "1px",
        }),
      }}
    >
      {label}
    </div>
  );
};

/**
 * @deprecated
 * We do not plan to provide a replacement for this component.
 * `Calendar` is part of the `DateInput` or `DateTimeInput`.
 * For information about the replacement, see the documentation for `DateInput` or `DateTimeInput`
 */
export const Calendar = ({ presets = [], ...props }: CalendarProps) => {
  const uiColors = useUiColors();
  const uiShadows = useUiShadows();

  const { gridProps, state } = useCalendar(props);

  return (
    <div
      {...gridProps}
      tabIndex={0}
      css={{
        display: "grid",
        outlineStyle: "none",
        alignContent: "flex-start",
        backgroundColor: uiColors.background,
        boxSizing: "border-box",
        width: "296px",
        ...cssFns.padding("8px"),
        boxShadow: uiShadows.bottomNormal,
        ...cssFns.border({ radius: "16px" }),
      }}
    >
      {state.view === "day" ? (
        <DaysView state={state} />
      ) : (
        <MonthsView state={state} />
      )}
      {presets.length > 0 && (
        <div css={{ display: "flex", flexWrap: "wrap" }}>
          {// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          presets?.map((preset) => (
            <Preset {...preset} key={preset.label} onPress={state.selectDate} />
          ))}
        </div>
      )}
    </div>
  );
};

export type { CalendarProps };
