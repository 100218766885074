/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useEffect } from "react";

import { cssFns, useCss } from "@superweb/css";
import { useIsMobile, useTypo, useUiColors } from "@superweb/ui";

import { Message, useMessage } from "#internal/intl";
import { IconServiceAppFrameYango, YFill } from "#internal/ui/icons";
import { LoginButton } from "#internal/ui/login-button";
import { useMetrika } from "#internal/metrika";

const YangoIcon = () => {
  const css = useCss();

  return (
    <YFill
      className={css({
        width: "100%",
        height: "100%",
      })}
    />
  );
};

const showWelcomeGoal = "c6a50bd4-5f13-4370-a216-88b888bf38bb";
const clickRegisterButtonGoal = "6eada476-d5de-4e37-a163-daa6287f1ddb";

export const WelcomeContent = ({
  redirectToPassport,
}: {
  redirectToPassport: () => void;
}) => {
  const typo = useTypo();
  const message = useMessage();
  const { reachGoal } = useMetrika();

  useEffect(() => {
    reachGoal(showWelcomeGoal);
  }, [reachGoal]);

  const handleButtonPress = () => {
    reachGoal(clickRegisterButtonGoal);
    redirectToPassport();
  };

  return (
    <div
      css={{
        display: "grid",
        gridTemplateRows: "1fr min-content",
        ...cssFns.padding("16px"),
        paddingBlockStart: "0",
      }}
    >
      <img
        src={new URL("welcome.png", import.meta.url).href}
        srcSet={`
        ${new URL("welcome_1.5x.png", import.meta.url).href} 1.5x, 
        ${new URL("welcome_2x.png", import.meta.url).href} 2x`}
        alt=""
        css={{ maxWidth: "320px", width: "100%", ...cssFns.margin("auto") }}
      />
      <div>
        <div
          css={{
            minHeight: "56px",
            display: "grid",
            ...cssFns.placeContent("center"),
            ...typo({ level: "body2", weight: "medium", density: "tight" }),
            textAlign: "center",
          }}
        >
          <Message
            id="0dd84eb5-9faf-4633-bf25-f69cdda19a50"
            context="Referral link. Entry screen. Description"
            default="Just a couple more steps and you're all done"
          />
        </div>
        <div css={{ display: "grid" }}>
          <LoginButton
            icon={YangoIcon}
            text={message({
              id: "b9945c06-a9c3-479f-afbd-d081587b8570",
              context: "Referral link. Entry screen. Button",
              default: "Register with Yango ID",
            })}
            size="l"
            onPress={handleButtonPress}
          />
        </div>
      </div>
    </div>
  );
};

export const WelcomeHeaderComponent = () => {
  const typo = useTypo();
  const uiColors = useUiColors();
  const isMobile = useIsMobile();

  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        ...cssFns.padding("12px"),
        backgroundColor: uiColors.background,
        position: "sticky",
        insetBlockStart: "0",
        ...(!isMobile && cssFns.borderBlockStart({ radius: "16px" })),
      }}
    >
      <IconServiceAppFrameYango />
      <h1
        css={{
          ...typo({
            level: "body1",
            weight: "bold",
            density: "tight",
          }),
          ...cssFns.margin("0", "8px"),
        }}
      >
        <Message
          id="87827663-f730-40e8-b988-22f6aaaea232"
          context="Self-registration welcome page. Title"
          default="Scout app"
        />
      </h1>
    </div>
  );
};
