/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useRef, type ComponentType, type ReactElement } from "react";
import { useButton, useFocusRing, useHover, mergeProps } from "react-aria";

import { cssFns } from "@superweb/css";
import { useTypo, useUiColors } from "@superweb/ui";

type View = "default" | "outline";
type Size = "l" | "m" | "s";
type Shape = "circle" | "squircle" | "brick";

const backgroundColors = {
  default: "#292933",
  hovered: "#1B1B26",
  pressed: "#0A0A19",
};

export const LoginButton = ({
  icon: Icon,
  onPress,
  shape = "squircle",
  size = "m",
  text,
  view = "default",
}: {
  onPress?: () => void;
  shape?: Shape;
  size?: Size;
  view?: View;

  /**
   * Visible text.
   * Also gives the button an accessible name.
   *
   * Should describe the action performed by the button.
   */
  text: string;

  /**
   * Icon component to be displayed in addition to visible text.
   */
  icon?: ComponentType<{ className?: string }>;
}): ReactElement => {
  const ref = useRef(null);
  const { buttonProps, isPressed } = useButton(
    {
      onPress,
    },
    ref,
  );
  const { hoverProps, isHovered } = useHover({});
  const { focusProps, isFocusVisible: isFocused } = useFocusRing();

  const typo = useTypo();
  const uiColors = useUiColors();

  const backgroundColor = (() => {
    if (view === "outline") {
      return isPressed
        ? cssFns.transparentize(backgroundColors.pressed, 0.85)
        : isHovered
          ? cssFns.transparentize(backgroundColors.hovered, 0.9)
          : "transparent";
    } else {
      return !(isPressed || isHovered)
        ? backgroundColors.default
        : !isPressed
          ? backgroundColors.hovered
          : backgroundColors.pressed;
    }
  })();

  const iconSize = size === "s" ? "18px" : "24px";

  const radius = {
    circle: "100px",
    squircle: "12px",
    brick: "0",
  }[shape];

  const focusedStyles = cssFns.boxShadow(
    {
      spreadRadius: "2px",
      color: uiColors.textInvert,
    },
    {
      spreadRadius: "4px",
      color: backgroundColors.default,
    },
  );

  const gap = size === "s" ? "8px" : "12px";

  return (
    <button
      {...mergeProps(buttonProps, hoverProps, focusProps)}
      ref={ref}
      css={{
        appearance: "none",
        ...cssFns.margin("0"),
        ...cssFns.padding("0"),
        ...cssFns.border({ style: "none" }),
        outlineStyle: "none",
        backgroundColor: "transparent",
      }}
    >
      <span
        css={{
          display: "grid",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
          boxSizing: "border-box",
          position: "relative",
          isolation: "isolate",
          ...cssFns.padding("0px", iconSize),
          gridTemplateColumns:
            Icon && text
              ? `[icon] ${iconSize} ${gap} [text] auto `
              : Icon
                ? `[icon] ${iconSize} `
                : `[text] auto`,
          ...cssFns.overflow("hidden"),
          ...cssFns.border({
            width: view === "outline" ? "1px" : "0",
            style: view === "outline" ? "solid" : "none",
            color:
              view === "outline" ? backgroundColors.default : "transparent",
            radius,
          }),
          ...(isFocused && focusedStyles),
          cursor: "pointer",
          height: { l: "56px", m: "44px", s: "36px" }[size],
          backgroundColor,
          color: view === "outline" ? uiColors.everBack : uiColors.textInvert,
          outlineStyle: "none",
        }}
      >
        {Icon && (
          <div
            css={{
              gridColumnStart: "icon",
              width: iconSize,
              height: iconSize,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Icon />
          </div>
        )}
        <span
          css={{
            ...typo({
              level: "body2",
              weight: "medium",
              density: "tight",
            }),
            ...cssFns.overflow("hidden"),
            gridColumnStart: "text",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {text}
        </span>
      </span>
    </button>
  );
};
