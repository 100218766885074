import type { UsersListPostRequestType } from "#internal/api/hiring-partners-app/users";

export const usersLink = ({ status }: UsersListPostRequestType = {}) => {
  const searchParams = new URLSearchParams();

  if (status) searchParams.set("status", status.toString());

  return `/users?${searchParams.toString()}`;
};

export const usersParams = (search: string): UsersListPostRequestType => {
  const searchParams = new URLSearchParams(search);

  return {
    status: searchParams.get("status") ?? undefined,
  };
};
