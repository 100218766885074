/**
 * Progressive 32-bit signed integer djb2 hash.
 *
 * Will always produce non-negative integers for non-zero length input.
 */
export const hash = (s?: string, h = 5381) => {
  if (s === undefined) {
    return h;
  }
  for (let i = 0; i < s.length; ++i) {
    h = ((h * 33) ^ s.charCodeAt(i)) >>> 0;
  }
  return h;
};

/**
 * Convert 32 bit hash to ASCII using characters in ranges b-z and A-Y.
 *
 * Only valid for positive 32-bit integers.
 */
export const name = (h: number) => {
  const base = 50;
  let s = "";
  do {
    const c = h % base;
    h = (h / base) | 0;
    s += String.fromCharCode(c + (c > 24 ? 40 : 98));
  } while (h);
  return s;
};

/**
 * Convert camelCase to dash-case.
 */
export const dash = (s: string) => {
  return s.startsWith("--") ? s : s.replace(/([A-Z])/g, "-$1").toLowerCase();
};

/**
 * Replace consequitive whitespace characters with single space
 * and trim leading and trailing whitespace.
 */
export const trim = (s: string) => {
  return s.replace(/\s+/g, " ").trim();
};

/**
 * Convert property-value pair to CSS syntax
 * and apply prefixed property fallbacks.
 */
export const decl = (p: string, v: string) => {
  let s = `${p}:${v}`;

  switch (p) {
    case "background-clip":
    case "backdrop-filter":
    case "backface-visibility":
      s = `-webkit-${s};${s}`;
      break;
  }

  return s;
};
