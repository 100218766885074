/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import {
  type ReactNode,
  type PointerEvent,
  type HTMLAttributeAnchorTarget,
  useMemo,
} from "react";
import { useIsMobile } from "../mobile-context";
import { DesktopMenu } from "./desktop-menu";
import { MobileMenu } from "./mobile-menu";
import { useNavMenuState } from "./menu-state";
import { isValidGroup } from "./group-validator";

/**
 * General type of navigation menu item combining all possible  item types
 */
export type MenuItem = MenuLink | MenuGroup;

/**
 * Navigation menu item for links
 */
export type MenuLink = {
  /**
   * Property indicating that this item is a link item
   */
  type: "link";

  /**
   *  Icon for navigation menu item
   */
  icon: ReactNode;

  /**
   * Text for navigation menu item
   */
  label: string;

  /**
   * Unique identifier for link
   */
  id?: string;

  link: {
    /**
     * Navigation url
     */
    href: string;
    /**
     * Callback fired when press on navigation menu item.
     * @param event - Link click event.
     */
    onClick?: (event: PointerEvent<HTMLAnchorElement>) => void;

    /*
     * Target attribute for tag <a>
     */
    target?: HTMLAttributeAnchorTarget;

    /*
     * Flag if true indicates that this link is currently active
     */
    isActive?: boolean;
  };
};

/**
 * Navigation menu item for grouping items
 */
export type MenuGroup = {
  /**
   * Property indicating that this item is a group item
   */
  type: "group";

  /**
   *  Icon for navigation menu item
   */
  icon: ReactNode;

  /**
   * Text for navigation menu item
   */
  label: string;

  /**
   * Unique identifier for button that opens group
   */
  id?: string;

  /**
   * List of sub-menu groups.
   * They help to group links within a sub-menu.
   */
  items: (SubMenuGroup | undefined | null | false)[];
};

/**
 * Group within the sub-menu
 */
export type SubMenuGroup = {
  /**
   * Title text of the sub-menu. Helps to separate links within sub-menu.
   */
  label?: string;

  /**
   * List of links of this sup-menu group
   */
  items: (SubMenuLink | undefined | null | false)[];
};

/**
 * Link within the sub-menu
 */
export type SubMenuLink = {
  /**
   * Text for navigation menu item
   */
  label: string;

  /**
   * Unique identifier for link
   */
  id?: string;

  link: {
    /**
     * Navigation url
     */
    href: string;
    /**
     * Callback fired when press on navigation menu item.
     * @param event - Link click event.
     */
    onClick?: (event: PointerEvent<HTMLAnchorElement>) => void;

    /*
     * Target attribute for tag <a>
     */
    target?: HTMLAttributeAnchorTarget;

    /*
     * Flag if true indicates that this link is currently active
     */
    isActive?: boolean;
  };
};

export const NavMenu = (props: {
  /**
   * Application logo placed at the top of the menu
   */
  logo: ReactNode;

  /**
   * The name of application, which is displayed in the header of the mobile menu
   */
  appName?: string;

  /**
   * Primary menu items placed at the top of the menu, under logo
   */
  primaryItems: (MenuItem | undefined | null | false)[];

  /**
   * Additional menu items placed at the bottom of the menu
   */
  secondaryItems: (MenuItem | undefined | null | false)[];

  /**
   * The type of event that opens the submenu: by clicking or by hovering the mouse
   * @defaultValue "click"
   */
  submenuTriggerType?: "click" | "hover";
}) => {
  const { submenuTriggerType } = props;

  const isMobile = useIsMobile();

  const state = useNavMenuState({
    submenuTriggerType,
  });

  const primaryItems = useMemo(() => {
    const items = [];

    for (const item of props.primaryItems) {
      if (item && (item.type === "link" || isValidGroup(item))) {
        items.push(item);
      }
    }

    return items;
  }, [props.primaryItems]);

  const secondaryItems = useMemo(() => {
    const items = [];

    for (const item of props.secondaryItems) {
      if (item && (item.type === "link" || isValidGroup(item))) {
        items.push(item);
      }
    }

    return items;
  }, [props.secondaryItems]);

  if (isMobile)
    return (
      <MobileMenu
        logo={props.logo}
        title={props.appName ?? "Menu"}
        primaryItems={primaryItems}
        secondaryItems={secondaryItems}
        state={state}
      />
    );
  else
    return (
      <DesktopMenu
        logo={props.logo}
        state={state}
        primaryItems={primaryItems}
        secondaryItems={secondaryItems}
      />
    );
};
