/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useEffect } from "react";

import { cssFns } from "@superweb/css";
import { LinkButton, useIsMobile, useTypo } from "@superweb/ui";

import { Message, useMessage } from "#internal/intl";
import { useMetrika } from "#internal/metrika";

import peopleImage from "./people.svg";

import { BaseLayout } from "../base-layout";

const showProfileFoundPageGoal = "09cad5f3-bd27-43ef-a7f5-28b359d49dc1";
const clickOpenYangoProLinkGoal = "b38b0c67-268e-4e65-98f0-9c1c09628f40";

export const ProfileFoundPageContent = () => {
  const typo = useTypo();
  const message = useMessage();
  const { reachGoal } = useMetrika();
  const isMobile = useIsMobile();

  useEffect(() => {
    reachGoal(showProfileFoundPageGoal);
  }, [reachGoal]);

  return (
    <BaseLayout>
      <div
        css={{
          display: "grid",
          rowGap: "32px",
          gridTemplateRows: "min-content 1fr min-content",
          ...cssFns.padding("8px"),
          paddingBlockStart: "60px",
        }}
      >
        <div
          css={{
            display: "grid",
            rowGap: "10px",
            ...cssFns.padding("0", "24px"),
          }}
        >
          <div
            css={{
              ...typo({ level: "body1", weight: "medium", density: "normal" }),
            }}
          >
            <Message
              id="f15991f8-d697-41e9-b075-24b5d88bf9e3"
              context="Self-registration form. Screen of the found active driver account. Title"
              default="Looks like you have an active driver account"
            />
          </div>
          <div
            css={{
              ...typo({ level: "body2", weight: "medium", density: "tight" }),
            }}
          >
            <Message
              id="3cb17ccd-9712-45b6-8976-e363eaa466b3"
              context="Self-registration form. Screen of the found active driver account. Description"
              default="If you want to invite new drivers to partner with us, share your personal referral code with them"
            />
          </div>
        </div>
        <img
          src={peopleImage}
          alt=""
          css={{
            maxWidth: isMobile ? "290px" : "360px",
            width: "80%",
            ...cssFns.margin("auto"),
          }}
        />
        <LinkButton
          text={message({
            id: "a676b07c-2858-4361-be4b-6c3a8717d51d",
            context:
              "Self-registration form. Screen of the found active driver account. Button",
            default: "Open in Pro",
          })}
          view="action"
          size="l"
          link={{
            href: "https://ubq5.adj.st/screen/invite_friend?adj_t=1c5p35v6_1c8xmy9g",
            external: true,
            onClick: () => {
              reachGoal(clickOpenYangoProLinkGoal);
            },
          }}
        />
      </div>
    </BaseLayout>
  );
};
