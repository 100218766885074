import type { LeadsListPostRequestType } from "#internal/api/hiring-partners-app/leads";

export const leadsLink = ({
  vacancies,
  phone,
  lead_created_at_gt,
  lead_created_at_lte,
  lead_updated_at_gt,
  lead_updated_at_lte,
  target_cities,
  statuses,
  external_ids,
  rewarded_at_gte,
  rewarded_at_lte,
  has_eats_reward,
}: LeadsListPostRequestType = {}) => {
  const searchParams = new URLSearchParams();

  if (vacancies?.length) searchParams.set("vacancies", vacancies.toString());
  if (phone) searchParams.set("phone", phone);
  if (external_ids) searchParams.set("external_ids", external_ids);
  if (lead_created_at_gt)
    searchParams.set("lead_created_at_gt", lead_created_at_gt);
  if (lead_created_at_lte)
    searchParams.set("lead_created_at_lte", lead_created_at_lte);
  if (lead_updated_at_gt)
    searchParams.set("lead_updated_at_gt", lead_updated_at_gt);
  if (lead_updated_at_lte)
    searchParams.set("lead_updated_at_lte", lead_updated_at_lte);
  if (target_cities?.length)
    searchParams.set("target_cities", target_cities.toString());
  if (statuses?.length) searchParams.set("statuses", statuses.toString());
  if (rewarded_at_gte) searchParams.set("rewarded_at_gte", rewarded_at_gte);
  if (rewarded_at_lte) searchParams.set("rewarded_at_lte", rewarded_at_lte);
  if (has_eats_reward)
    searchParams.set("has_eats_reward", has_eats_reward.toString());

  return `/leads?${searchParams.toString()}`;
};

export const leadsParams = (search: string): LeadsListPostRequestType => {
  const searchParams = new URLSearchParams(search);

  return {
    vacancies: searchParams.get("vacancies")?.split(",") ?? undefined,
    phone: searchParams.get("phone") ?? undefined,
    external_ids: searchParams.get("external_ids") ?? undefined,
    lead_created_at_gt: searchParams.get("lead_created_at_gt") ?? undefined,
    lead_created_at_lte: searchParams.get("lead_created_at_lte") ?? undefined,
    lead_updated_at_gt: searchParams.get("lead_updated_at_gt") ?? undefined,
    lead_updated_at_lte: searchParams.get("lead_updated_at_lte") ?? undefined,
    target_cities: searchParams.get("target_cities")?.split(",") ?? undefined,
    statuses: searchParams.get("statuses")?.split(",") ?? undefined,
    rewarded_at_gte: searchParams.get("rewarded_at_gte") ?? undefined,
    rewarded_at_lte: searchParams.get("rewarded_at_lte") ?? undefined,
    has_eats_reward: searchParams.get("has_eats_reward") === "true",
  };
};
