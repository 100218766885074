import type { Style } from "../style";

export const flex = ({
  grow,
  shrink,
  basis,
}: {
  grow: string;
  shrink?: string;
  basis?: string;
}): Style => ({
  flexGrow: grow,
  ...(shrink && { flexShrink: shrink }),
  ...(basis && { flexBasis: basis }),
});
