import { isValidPhoneNumber } from "libphonenumber-js";

import { useMessage } from "#internal/intl";

export const useValidators = () => {
  const message = useMessage();

  const validateRequired = (value?: string) =>
    !value?.trim()
      ? message({
          id: "5618da5b-42f5-43cb-a7c3-95eface88a3d",
          context: "Self-registration form. Error",
          default: "Required field",
        })
      : undefined;

  const validatePhone = (value: string) =>
    !isValidPhoneNumber(value)
      ? message({
          id: "0300c758-ad59-4e20-8a39-80ad15f7eaee",
          context: "Self-registration form. Field error",
          default: "Invalid phone number",
        })
      : undefined;

  const validateTelegram = (value?: string) =>
    value && !/^[A-Za-z0-9_]{5,32}$/.test(value)
      ? message({
          id: "4afa8967-ff5f-49a6-ad36-0cfee36e050e",
          context: "Self-registration form. Field error",
          default: "Invalid telegram login",
        })
      : undefined;

  return {
    validateRequired,
    validatePhone,
    validateTelegram,
  };
};
