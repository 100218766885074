/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import {
  Component,
  type PropsWithChildren,
  type ComponentType,
  type ErrorInfo,
  type ReactNode,
} from "react";

import { logError } from "#internal/rum";

const areArraysEqual = (a: Array<any> = [], b: Array<any> = []) => {
  if (a.length !== b.length) {
    return false;
  }
  return a.every((item, index) => Object.is(item, b[index]));
};

type Without<T> = { [P in keyof T]?: never };

type XOR<T, U> = (Without<T> & U) | (Without<U> & T);

export type FallbackProps = {
  error: Error;
  reset: () => void;
};

export type Props = PropsWithChildren<{
  resetKey?: Array<any>;
  throwIf?: (e: Error) => boolean;
  onReset?: () => void;
}> &
  XOR<
    {
      fallbackRender: ComponentType<FallbackProps>;
    },
    { fallback: ReactNode }
  >;

type State = {
  error?: Error;
  prevResetKey: Props["resetKey"];
};

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { prevResetKey: props.resetKey };
  }

  static getDerivedStateFromError(error: Error, prevState: State): State {
    return {
      ...prevState,
      error,
    };
  }

  static getDerivedStateFromProps(props: Props, prevState: State): State {
    const error =
      prevState.error && !areArraysEqual(props.resetKey, prevState.prevResetKey)
        ? undefined
        : prevState.error;

    return {
      prevResetKey: props.resetKey,
      error,
    };
  }

  override shouldComponentUpdate(nextProps: Props, nextState: State) {
    if (
      this.state.error !== nextState.error ||
      this.props.children !== nextProps.children
    ) {
      return true;
    }
    return (
      Boolean(nextState.error) &&
      !areArraysEqual(nextProps.resetKey, this.props.resetKey)
    );
  }

  override componentDidCatch(error: Error, { componentStack }: ErrorInfo) {
    logError(
      {
        message: error.message,
        additional: { componentStack },
      },
      error,
    );
  }

  reset() {
    this.props.onReset?.();
    this.setState({ prevResetKey: this.props.resetKey, error: undefined });
  }

  override render() {
    if (this.state.error) {
      if (this.props.throwIf && this.props.throwIf(this.state.error)) {
        throw this.state.error;
      }

      if (this.props.fallbackRender) {
        const { fallbackRender: Fallback } = this.props;
        return <Fallback error={this.state.error} reset={() => this.reset()} />;
      }
      return this.props.fallback;
    }

    return this.props.children;
  }
}
