/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import {
  useEffect,
  useMemo,
  useState,
  useCallback,
  type ComponentType,
} from "react";

import {
  parsePhoneNumberFromString as parsePhoneNumber,
  isValidPhoneNumber,
} from "libphonenumber-js";

import { TextInput } from "@superweb/ui";
import { usePreviousState } from "@superweb/ui";

const phoneRegEx = /^[0-9*#+()-]+$/;

export { parsePhoneNumber };

export const isValidPhone = isValidPhoneNumber;

/** use PhoneField from `@superweb/ui` */
export const PhoneInput = ({
  disabled,
  value,
  ariaLabel,
  ariaErrorMessage,
  errorMessage,
  checkValidity,
  onChange,
  icon,
  placeholder,
}: {
  disabled?: boolean;
  value: string;
  ariaLabel: string;
  ariaErrorMessage?: string;
  errorMessage?: string;
  checkValidity?: (isValid: boolean) => void;
  onChange: (value: string) => void;
  icon?: ComponentType<{ className?: string }>;
  placeholder?: string;
}) => {
  const prevValue = usePreviousState(value) ?? "";

  const [isFocused, setIsFocused] = useState(false);
  const [phone, setPhone] = useState(parsePhoneNumber(value)?.number);

  const isValid = useMemo(() => isValidPhone(phone ?? ""), [phone]);

  const formattedValue = useMemo(() => {
    if (!isValidPhone(phone ?? "")) return undefined;

    const formattedNumber = parsePhoneNumber(
      phone ?? "",
    )?.formatInternational();

    return formattedNumber;
  }, [phone]);

  const setNumber = useCallback(
    (next: string) => {
      if (next.charAt(0) === "8") {
        next = `+7${next.substring(1)}`;
      }
      if (next.length && next.charAt(0) !== "+") {
        next = `+${next}`;
      }

      const nextPhone = parsePhoneNumber(next);

      setPhone(nextPhone?.number);

      checkValidity?.(isValidPhone(nextPhone?.number ?? ""));
      onChange(nextPhone?.number ?? next);
    },
    [onChange, checkValidity],
  );

  const phoneValue = useMemo(
    () => (phoneRegEx.test(value) ? value : ""),
    [value],
  );

  useEffect(() => {
    if (prevValue !== phoneValue) {
      setNumber(phoneValue);
    }
  }, [phoneValue, prevValue, setNumber]);

  return (
    <TextInput
      disabled={disabled}
      value={isFocused ? phoneValue : formattedValue ?? phoneValue}
      placeholder={placeholder ?? "+"}
      onChange={(e) => {
        const nextValue = e.target.value;
        if (nextValue && !phoneRegEx.test(nextValue)) return;
        setNumber(nextValue);
      }}
      inputProps={{
        "aria-label": ariaLabel,
        "aria-invalid": `${!isValid}`,
        "aria-errormessage": ariaErrorMessage,
        onFocus: () => setIsFocused(true),
        onBlur: () => setIsFocused(false),
        type: "tel",
        inputMode: "tel",
      }}
      icon={icon}
      errorMessage={errorMessage}
    />
  );
};
