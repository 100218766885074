/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { cssFns } from "@superweb/css";
import { LinkButton, useTypo } from "@superweb/ui";

import { Message, useMessage } from "#internal/intl";
import { agentsLink, useMakeLink } from "#internal/routes";
import { useMetrika } from "#internal/metrika";

import { BaseLayout } from "../base-layout";

const buttonPressGoal = "e5433571-3d27-46e4-a3cb-fc4def9355be";

export const FinalScreen = () => {
  const typo = useTypo();
  const message = useMessage();
  const { reachGoal } = useMetrika();
  const makeLink = useMakeLink();

  return (
    <BaseLayout>
      <div
        css={{
          display: "grid",
          gridTemplateRows: "1fr min-content",
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            ...cssFns.padding("0", "32px"),
            paddingBlockStart: "65px",
          }}
        >
          <div
            css={{
              ...typo({
                level: "body1",
                weight: "medium",
                density: "normal",
              }),
              marginBlockEnd: "6px",
            }}
          >
            <Message
              id="e886e689-81bb-44f8-bffa-e8d4e850c4d1"
              context="Final screen of the self-registration page. Title"
              default="All done!"
            />
          </div>
          <div
            css={{
              ...typo({
                level: "body2",
                weight: "medium",
                density: "tight",
              }),
            }}
          >
            <Message
              id="d9b37568-c5fa-4af2-8e16-f861105194e6"
              context="Final screen of the self-registration page. Subtitle"
              default="Welcome to the team"
            />
          </div>

          <div
            css={{
              ...cssFns.margin("20px", "0"),
              flexGrow: "1",
              display: "grid",
              ...cssFns.placeItems("center"),
            }}
          >
            <img
              src={new URL("final-screen.png", import.meta.url).href}
              srcSet={`
          ${new URL("final-screen_1.5x.png", import.meta.url).href} 1.5x, 
          ${new URL("final-screen_2x.png", import.meta.url).href} 2x`}
              alt=""
              css={{
                maxWidth: "288px",
                width: "100%",
              }}
            />
          </div>
        </div>
        <div
          css={{
            display: "grid",
            ...cssFns.padding("8px"),
          }}
        >
          <LinkButton
            view="action"
            text={message({
              id: "af9b5e87-fd09-4264-a2d5-83fc9ec54e20",
              context:
                "Final screen of the self-registration page. Button text",
              default: "Amazing",
            })}
            link={makeLink(agentsLink())}
            onPress={() => reachGoal(buttonPressGoal)}
            size="l"
          />
        </div>
      </div>
    </BaseLayout>
  );
};
