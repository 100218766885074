let m: Set<string> | undefined = undefined;
let e: HTMLStyleElement | undefined = undefined;

/**
 * Remove style element from DOM and reset rule injection cache.
 * Used in tests to reset to a clean environment.
 */
export const reset = () => {
  e?.remove();
  m = undefined;
  e = undefined;
};

/**
 * Check that rules for a single id (className) are not yet injected.
 */
export const isInjected = (id: string) => {
  return m?.has(id) ?? false;
};

/**
 * Inject a set of rules with a single id (className).
 */
export const inject = ({
  id,
  rules,
  nonce,
}: {
  id: string;
  rules: string[];
  nonce: string | undefined;
}) => {
  if (!e) {
    m = new Set();
    e = document.createElement("style");
    e.nonce = nonce;
    document.head.append(e);
  }
  for (const rule of rules) {
    e!.sheet!.insertRule(rule);
  }
  m!.add(id);
};
