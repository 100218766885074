/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { useState, type Dispatch, type SetStateAction } from "react";

import { cssFns } from "@superweb/css";
import { Button, useTypo } from "@superweb/ui";

import { Message, useMessage } from "#internal/intl";
import type { HttpError } from "#internal/api/fetch";
import { useMetrika } from "#internal/metrika";

import { UploadField, type fileFieldState } from "./upload-field";
import imageId from "./images/id.png";
import imageId_2 from "./images/id_2x.png";
import imageSelfie from "./images/selfie.png";
import imageSelfie_2 from "./images/selfie_2x.png";

const acceptedFormats = ["image/png", "image/jpg", "image/jpeg"];

export const QualityControl = ({
  countryCode,
  uploadFileGoal,
  onUpload,
  onSubmit,
  isLoading,
  isButtonDisabled,
}: {
  countryCode: string;
  uploadFileGoal?: string;
  onUpload: (value: Partial<Record<"selfie" | "photo", string>>) => void;
  onSubmit: () => void;
  isLoading?: boolean;
  isButtonDisabled?: boolean;
}) => {
  const typo = useTypo();
  const message = useMessage();
  const { reachGoal } = useMetrika();

  const [photoState, setPhotoState] = useState({
    id: "",
    version: "",
  });
  const [selfieState, setSelfieState] = useState({
    id: "",
    version: "",
  });

  const handleSetUploadData =
    (
      fieldName: "selfie" | "photo",
      setterState: Dispatch<SetStateAction<fileFieldState>>,
    ) =>
    ({ data, errorData }: { data?: fileFieldState; errorData?: HttpError }) => {
      if (errorData?.status) {
        const { message, status } = errorData;
        if (uploadFileGoal) {
          reachGoal(uploadFileGoal, {
            result: { message, status: status.toString() },
          });
        }
        return;
      }

      if (!data) {
        return;
      }

      setterState(data);
      onUpload({ [fieldName]: data.id });
      if (data.id && uploadFileGoal) {
        reachGoal(uploadFileGoal, { result: "success" });
      }
    };

  return (
    <form css={{ display: "grid", gridTemplateRows: "1fr min-content" }}>
      <div
        css={{
          display: "grid",
          gridTemplateRows: "min-content 1fr",
          ...cssFns.padding("0", "16px"),
          marginBlockEnd: "16px",
        }}
      >
        <fieldset
          css={{
            display: "grid",
            ...cssFns.border({ style: "none" }),
            ...cssFns.padding("0"),
            ...cssFns.margin("0"),
          }}
        >
          <legend
            css={{
              boxSizing: "border-box",
              ...typo({ level: "body2", weight: "medium", density: "normal" }),
              minHeight: "56px",
              ...cssFns.padding("9px", "0"),
              paddingBlockEnd: "16px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Message
              id="1bfdb63c-b72e-4370-b895-be5ecbc4b027"
              context="Self-registration form. Fields' block title"
              default="Take a picture of your ID and a selfie. We need this to make sure it's really you."
            />
          </legend>
          <div
            css={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              columnGap: "8px",
              paddingBlockEnd: "25px",
            }}
          >
            <UploadField
              title={message({
                id: "c581e242-b29f-4afa-849c-adeb1658c864",
                context: "Referral page. Upload field",
                default: "Photo of ID",
              })}
              setData={handleSetUploadData("photo", setPhotoState)}
              id={photoState.id}
              version={photoState.version}
              countryCode={countryCode}
              formats={acceptedFormats}
            />
            <UploadField
              title={message({
                id: "6473bd25-5187-415d-ae94-45e2a7d6b767",
                context: "Referral page. Upload field",
                default: "Selfie",
              })}
              setData={handleSetUploadData("selfie", setSelfieState)}
              id={selfieState.id}
              version={selfieState.version}
              countryCode={countryCode}
              formats={acceptedFormats}
            />
          </div>
        </fieldset>
        <div
          css={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            ...cssFns.gap("16px", "8px"),
            justifyItems: "stretch",
            alignItems: "end",
            alignContent: "end",
          }}
        >
          <span
            css={{
              ...cssFns.gridColumn("span 2"),
              ...typo({ level: "body2", weight: "medium", density: "normal" }),
            }}
          >
            <Message
              id="f71439ff-3159-41a6-8f62-ada32795a81a"
              context="Referral page. Photo block text description"
              default="Make sure documents are visible like on the images below"
            />
          </span>
          <img src={imageId} srcSet={`${imageId_2} 2x`} alt="" />
          <img src={imageSelfie} srcSet={`${imageSelfie_2} 2x`} alt="" />
        </div>
      </div>
      <div
        css={{
          display: "grid",
          ...cssFns.padding("8px"),
        }}
      >
        <Button
          size="l"
          view="action"
          text={message({
            id: "51922790-def9-472a-b1dc-7ac49d589486",
            context: "Self-registration form. Button label",
            default: "Finish",
          })}
          onPress={onSubmit}
          progress={isLoading}
          disabled={isButtonDisabled}
        />
      </div>
    </form>
  );
};
