/* @jsxRuntime automatic */
/* @jsxImportSource @superweb/css */

import { createContext, useCallback, useContext, type ReactNode } from "react";

type MetrikaState = {
  counterId: number;
};

const MetrikaContext = createContext<MetrikaState | undefined>(undefined);

export const MetrikaProvider = ({
  children,
  counterId,
}: {
  children: ReactNode;
} & MetrikaState) => {
  return (
    <MetrikaContext.Provider value={{ counterId }}>
      {children}
    </MetrikaContext.Provider>
  );
};

export const useMetrika = () => {
  const context = useContext(MetrikaContext);
  if (context === undefined) {
    throw new Error("useMetrika outside of MetrikaProvider");
  }
  const { counterId } = context;

  const { ym } = window as unknown as { ym?: (...args: unknown[]) => void };

  /**
   * https://yandex.ru/support/metrica/objects/addfileextension.html
   */
  const addFileExtension = useCallback(
    (extensions: string | string[]) =>
      ym?.(counterId, "addFileExtension", extensions),
    [ym, counterId],
  );

  /**
   * https://yandex.ru/support/metrica/objects/extlink.html
   */
  const extLink = useCallback(
    (
      url: string,
      options?: {
        callback?: () => void;
        ctx?: Record<PropertyKey, unknown>;
        params?: Record<PropertyKey, unknown>;
        title?: string;
      },
    ) => ym?.(counterId, "extLink", url, options),
    [ym, counterId],
  );

  /**
   * https://yandex.ru/support/metrica/objects/file.html
   */
  const file = useCallback(
    (
      url: string,
      options?: {
        callback?: () => void;
        ctx?: Record<PropertyKey, unknown>;
        params?: Record<PropertyKey, unknown>;
        referer?: string;
        title?: string;
      },
    ) => ym?.(counterId, "file", url, options),
    [ym, counterId],
  );

  /**
   * https://yandex.ru/support/metrica/objects/get-client-id.html
   */
  const getClientID = useCallback(
    (clientIdCallback: (clientID: string) => void) =>
      ym?.(counterId, "getClientID", clientIdCallback),
    [ym, counterId],
  );

  /**
   * https://yandex.ru/support/metrica/objects/hit.html
   * https://yandex.ru/support/metrica/code/counter-spa-setup.html
   */
  const hit = useCallback(
    (
      url: string,
      options?: {
        callback?: () => void;
        ctx?: Record<PropertyKey, unknown>;
        params?: Record<PropertyKey, unknown>;
        referer?: string;
        title?: string;
      },
    ) => ym?.(counterId, "hit", url, options),
    [ym, counterId],
  );

  /**
   * https://yandex.ru/support/metrica/data/visit-params.html
   * https://yandex.ru/support/metrica/objects/params-method.html
   */
  const params = useCallback(
    (
      parameters: Record<PropertyKey, unknown> | Record<PropertyKey, unknown>[],
    ) => ym?.(counterId, "params", parameters),
    [ym, counterId],
  );

  /**
   * https://yandex.ru/support/metrica/objects/reachgoal.html
   */
  const reachGoal = useCallback(
    (
      target: string,
      params?: Record<PropertyKey, unknown>,
      callback?: () => void,
      ctx?: Record<PropertyKey, unknown>,
    ) => ym?.(counterId, "reachGoal", target, params, callback, ctx),
    [ym, counterId],
  );

  /**
   * https://yandex.ru/support/metrica/objects/set-user-id.html
   */
  const setUserID = useCallback(
    (userID: string) => ym?.(counterId, "setUserID", userID),
    [ym, counterId],
  );

  /**
   * https://yandex.ru/support/metrica/objects/user-params.html
   */
  const userParams = useCallback(
    (parameters: Record<PropertyKey, unknown>) =>
      ym?.(counterId, "userParams", parameters),
    [ym, counterId],
  );

  return {
    addFileExtension,
    extLink,
    file,
    getClientID,
    hit,
    params,
    reachGoal,
    setUserID,
    userParams,
  };
};
