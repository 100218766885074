import { rumEnabled } from "./consts";

export const logError = (...args: Parameters<typeof Ya.Rum.logError>) => {
  const { logError } = getYaRum();
  logError?.(...args);
};

export const updateErrors = (
  ...args: Parameters<typeof Ya.Rum.updateErrors>
) => {
  const { updateErrors } = getYaRum();
  updateErrors?.(...args);
};

export const setVars = (...args: Parameters<typeof Ya.Rum.setVars>) => {
  const { setVars } = getYaRum();
  setVars?.(...args);
};

export const sendTimeMark = (
  ...args: Parameters<typeof Ya.Rum.sendTimeMark>
) => {
  const { sendTimeMark } = getYaRum();
  sendTimeMark?.(...args);
};

export const sendHeroElement = (
  ...args: Parameters<typeof Ya.Rum.sendHeroElement>
) => {
  const { sendHeroElement } = getYaRum();
  sendHeroElement?.(...args);
};

const getYaRum = (): Partial<typeof Ya.Rum> => {
  if (!rumEnabled) return {};
  const w = window as unknown as { Ya?: typeof Ya };
  return w.Ya?.Rum ?? {};
};
